import { AuthModuleAPI } from "api/auth/auth_apis";
import firebase from "firebase/app";
import type { AuthIdleActions } from "services/auth/auth_actions_interfaces";
import { authMachineService } from "services/auth/authMachine/authMachine";

export type FirebaseAuthIdleActions = typeof firebaseAuthIdleActions;
export const firebaseAuthIdleActions = /* #__PURE__ */ new (class
  implements AuthIdleActions {
  signIn = async (info: AuthModuleAPI.SignInDetails) => {
    const res = await AuthModuleAPI.signInWithEmailAndPassword(info);
    if (res.success) {
      const user = await this.loginWithCustomToken(res.token);
      if (user) {
        authMachineService.send({
          type: "LOGIN_WITH_EMAIL_SUCCESS",
          data: user,
        });
        return;
      }
      throw new Error("Login Failed");
    } else {
      throw new Error(res.errorCode);
    }
  };
  signUp = async (info: AuthModuleAPI.SignUpDetails) => {
    const res = await AuthModuleAPI.signUp(info);
    if (res.success) return;
    throw new Error(res.errorCode);
  };
  idProviderSignIn = (provider: "GOOGLE" | "GITHUB") => {
    authMachineService.send({ type: "LOGIN_WITH_PROVIDER", provider });
  };
  signInWithProvider = async (providerString: "GOOGLE" | "GITHUB") => {
    if (providerString === "GITHUB") {
      return initGithubSignIn();
    } else if (providerString === "GOOGLE") {
      return initGoogleSignIn();
    }
    throw new Error("Unsupported provider");
  };
  signInWithPopUp = async (providerString: "GOOGLE" | "GITHUB") => {
    await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
    try {
      let provider;
      if (providerString === "GOOGLE") {
        provider = new firebase.auth.GoogleAuthProvider();
        provider.setCustomParameters({
          prompt: "select_account",
        });
      } else {
        provider = new firebase.auth.GithubAuthProvider();
      }
      const result = await firebase.auth().signInWithPopup(provider);
      return result.user;
    } catch (error) {
      //TODO handle error codes
      throw new Error("SIGN_IN_WITH_POPUP_FAILED");
    }
  };
  loginWithCustomToken = async (token: string) => {
    try {
      const result = await firebase.auth().signInWithCustomToken(token);
      if (result.user) {
        return result.user;
      }
      //TODO handle error
      return null;
    } catch (error) {
      //TODO handle error codes
      const errorCode = error.code;
      if (errorCode === "auth/invalid-custom-token") {
        //TODO send error
      } else {
        console.error(error);
      }
      throw new Error("LOGIN WITH CUSTOM TOKEN FAILED");
    }
  };

  verifyEmail = (actionCode: string) => {
    return firebase.auth().applyActionCode(actionCode);
  };

  sendPasswordResetEmail = async (email: string) => {
    const hints: Record<string, string> = {
      "auth/invalid-email": "INVALID_EMAIL",
      "auth/user-not-found": "USER_NOT_FOUND",
      "auth/invalid-continue-uri": "SYSTEM_ERROR",
      "auth/unauthorized-continue-uri": "SYSTEM_ERROR",
      "auth/missing-continue-uri": "SYSTEM_ERROR",
    };
    try {
      return await firebase.auth().sendPasswordResetEmail(email, {
        url: `${window.location.origin}/login?msg=reset_success`,
      });
    } catch (error) {
      const code = (error as firebase.FirebaseError).code || "Empty";
      const errorCode = hints[code] || "SYSTEM_ERROR";
      if (errorCode) {
        if (error === "USER_NOT_FOUND") {
          return;
        }
        throw new Error(errorCode);
      }
    }
  };

  verifyPasswordResetCode = (actionCode: string) => {
    return firebase.auth().verifyPasswordResetCode(actionCode);
  };
  confirmPwdReset = async ({
    actionCode,
    newPassword,
  }: {
    actionCode: string;
    newPassword: string;
  }) => {
    const hints: Record<string, PWD_RESET_ERRORS> = {
      "auth/invalid-action-code": "ALREADY_USED_OR_INVALID",
      "auth/expired-action-code": "EXPIRED_CODE",
      "auth/user-not-found": "USER_NOT_FOUND",
      "auth/user-disabled": "SYSTEM_ERROR",
      "auth/weak-password": "WEAK_PASSWORD",
      "auth/network-request-failed": "NETWORK_ERROR",
    };
    try {
      return await firebase
        .auth()
        .confirmPasswordReset(actionCode, newPassword);
    } catch (error) {
      const code = (error as firebase.FirebaseError).code || "Empty";
      const errorCode = hints[code] || "SYSTEM_ERROR";
      throw new Error(errorCode);
    }
  };

  subscribeToTokenChange = () => {
    firebase.auth().onIdTokenChanged(function (user) {
      if (user) {
        // User is signed in or token was refreshed.
        // user.getIdToken;
      }
    });
  };
})();

export type PWD_RESET_ERRORS =
  | "ALREADY_USED_OR_INVALID"
  | "EXPIRED_CODE"
  | "USER_NOT_FOUND"
  | "SYSTEM_ERROR"
  | "WEAK_PASSWORD"
  | "NETWORK_ERROR";

async function initGoogleSignIn() {
  await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
  try {
    const provider = new firebase.auth.GoogleAuthProvider();
    provider.setCustomParameters({
      prompt: "select_account",
    });
    return firebase.auth().signInWithRedirect(provider);
  } catch (error) {
    //TODO handle error codes
  }
}

async function initGithubSignIn() {
  await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
  try {
    const provider = new firebase.auth.GithubAuthProvider();
    return firebase.auth().signInWithRedirect(provider);
  } catch (error) {
    //TODO handle error codes
  }
}
