import { cacheAssets } from "modules/VersionUpdater/cacheAssets";
import { SW } from "modules/VersionUpdater/wb";
import { StrictMode } from "react";
import ReactDOM from "react-dom";
import { authMachineService } from "services/auth/authMachine/authMachine";
import { BROWSER } from "services/browser_info";
import { Intercom } from "services/intercom";
import { Logger } from "services/logger";
import { SentryService } from "services/sentry";
import { noop } from "utls/noop";
import App from "./App";
import "./index.scss";
import "./polyfills";

window.X_APP_LOADED = true;
Logger.bundle("app");
window.performance.mark("init_render");
ReactDOM.render(
  <StrictMode>
    <App />
  </StrictMode>,
  document.getElementById("root")
);
window.performance.mark("init_auth_machine");
authMachineService.init();
SentryService.init();

if (BROWSER.serviceWorkerEnabled) {
  SW.registerSW();
} else {
  SW.unregisterSW();
  if (BROWSER.precacheEnabled) {
    cacheAssets().catch(noop);
  }
}

Intercom.init();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
