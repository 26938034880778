import { IRoute } from "navigation/routes/IRoute";
import React from "react";
import { AUTH_STATES } from "services/auth/authMachine/interfaces";
import {
  CLI_LOGIN_PATH,
  EMAIL_VERIFIED_PATH,
  LOGIN_PATH,
} from "services/auth/auth_routes";
import { DashboardLazy } from "./DashboardLazy";

/** Application Routes */
let appRoutes: IRoute[];

if (process.env.REACT_APP_AUTH_ENV !== "ON_PREM") {
  const getAuthPages = () =>
    import(
      /* webpackChunkName: "auth" */ /* webpackPrefetch: true */ "./authPages"
    );
  const ForgotPwdPage = React.lazy(() =>
    getAuthPages().then((m) => m.ForgotPwd)
  );
  const LoginPage = React.lazy(() => getAuthPages().then((m) => m.Login));
  const RegisterPage = React.lazy(() => getAuthPages().then((m) => m.Register));
  const CliLoginPage = React.lazy(() => getAuthPages().then((m) => m.CliLogin));
  const EmailActionHandler = React.lazy(() =>
    getAuthPages().then((m) => m.EmailActionHandler)
  );
  appRoutes = [
    {
      text: "Login",
      path: LOGIN_PATH,
      component: <LoginPage />,
      requiredState: AUTH_STATES.AWAIT_LOGIN_REGISTER,
    },
    // {
    //   text: "Register",
    //   path: SIGNUP_PATH,
    //   component: <RegisterPage />,
    //   requiredState: AUTH_STATES.AWAIT_LOGIN_REGISTER,
    // },
    {
      text: "Forgot Password",
      path: "/reset-password",
      component: <ForgotPwdPage />,
      requiredState: AUTH_STATES.AWAIT_LOGIN_REGISTER,
    },
    {
      text: "Platformer CLI login",
      path: CLI_LOGIN_PATH,
      component: <CliLoginPage />,
      requiredState: AUTH_STATES.CLI_LOGIN,
    },
    {
      text: "Email verification",
      path: EMAIL_VERIFIED_PATH,
      component: <EmailActionHandler />,
      requiredState: AUTH_STATES.AWAIT_LOGIN_REGISTER,
    },
    {
      text: "Dashboard",
      path: "/",
      exact: false,
      component: <DashboardLazy />,
      requiredState: AUTH_STATES.LOGGED_IN,
    },
  ];
}

if (process.env.REACT_APP_AUTH_ENV === "ON_PREM") {
  const LoginPage = React.lazy(
    () =>
      import(
        /* webpackChunkName: "onprem_auth" */ /* webpackPrefetch: true */ "pages/LoginPageOnPrem"
      )
  );
  appRoutes = [
    {
      text: "Login",
      path: LOGIN_PATH,
      component: <LoginPage />,
      requiredState: AUTH_STATES.AWAIT_LOGIN_REGISTER,
    },
    {
      text: "Dashboard",
      path: "/",
      exact: false,
      component: <DashboardLazy />,
      requiredState: AUTH_STATES.LOGGED_IN,
    },
  ];
}

export { appRoutes };
