import {
  PageLoader,
  PageLoaderProvider,
} from "components/PageLoader/PageLoader";
import { ErrorBoundary } from "containers/ErrorBoundary/ErrorBoundary";
import { GlobalErrorBoundary } from "containers/ErrorHandlers/GlobalErrorBoundary";
import DevTools from "DevTools";
import { AuthHandler } from "modules/Auth/AuthHandler/AuthHandler";
import { InvalidRoute } from "navigation/InvalidRoute";
import { browserHistory } from "navigation/routerHistory";
import { appRoutes } from "navigation/routes/appRoutes";
import { queryClientV3X } from "queries/queryConfig";
import * as React from "react";
import { QueryClientProvider } from "react-query";
import { Route, Router, Switch } from "react-router-dom";
import { authMachineService } from "services/auth/authMachine/authMachine";
import "./App.scss";
import ThemeProvider from "./theme/ThemeProvider";

function App() {
  return (
    <QueryClientProvider client={queryClientV3X}>
      <ThemeProvider>
        <PageLoaderProvider>
          <GlobalErrorBoundary>
            <AuthHandler>
              <Router history={browserHistory}>
                <AppContent />
              </Router>
            </AuthHandler>
          </GlobalErrorBoundary>
        </PageLoaderProvider>
      </ThemeProvider>
      <ErrorBoundary fallbackUI={null}>
        <React.Suspense fallback={null}>
          <DevTools />
        </React.Suspense>
      </ErrorBoundary>
    </QueryClientProvider>
  );
}

function AppContent() {
  return (
    <div className="App">
      <React.Suspense fallback={<PageLoader />}>
        <Switch>
          {appRoutes.map((r, i) => (
            <Route
              path={r.path}
              key={r.path}
              render={() => {
                const allowed = authMachineService.state.matches(
                  r.requiredState
                );
                return allowed ? r.component : <InvalidRoute />;
              }}
              // Unless explicitly specified as 'false'
              // 'exact' should default to 'true'.
              exact={r.exact !== false}
            />
          ))}
        </Switch>
      </React.Suspense>
    </div>
  );
}

export default App;
