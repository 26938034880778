import { AUTH_ERRORS } from "api/auth/error_codes";
import { HEADERS } from "api/headers";
import { PXAPIError } from "api/responseInterceptor";
import {
  CustomRoleDocument,
  OrgDocument,
  ProjectDocument,
  RoleCollectionDocument,
  UserDocument,
  UserEnvMapping,
  UserOrgMapping,
  UserProjectMapping,
} from "shared/interfaces/document_interfaces";
import { authInstance } from "./authInstance";
import {
  EnvUserAddData,
  OrgCreateData,
  OrgCreateResponse,
  OrgCustomRoleAddData,
  OrgCustomRoleEditData,
  OrgEditData,
  OrgUpdateResponse,
  OrgUserEditData,
  OrgUserRemoveData,
  PendingUserRemoveData,
  ProjectCreateData,
  ProjectCustomRoleAddData,
  ProjectCustomRoleEditData,
  ProjectUserAddData,
  ProjectUserEditData,
  ProjectUserRemoveData,
} from "./interfaces";
import { removeServiceAccounts } from "./removeServiceAccounts";

export * from "./interfaces";

export async function getUserDoc() {
  const PATH = `/organization/user/get`;
  try {
    const res = await authInstance.get(PATH);
    const apiRes = res.data;
    return apiRes.data as UserDocument;
  } catch (e) {
    if (e instanceof PXAPIError) throw e;
    throw new PXAPIError("Error occured", "ERROR", { error: e });
  }
}

export async function createOrganization(
  data: OrgCreateData
): Promise<OrgCreateResponse> {
  try {
    const res = await authInstance.post("/organization/create", data, {});
    return res.data;
  } catch (e) {
    if (e instanceof PXAPIError) throw e;
    throw new PXAPIError("Error occured", "ERROR", e);
  }
}

export async function updateOrganization(
  data: OrgEditData
): Promise<OrgUpdateResponse> {
  try {
    const res = await authInstance.put("/organization/edit", data, {});
    return res.data;
  } catch (e) {
    if (e instanceof PXAPIError) throw e;
    throw new PXAPIError("Error occured", "ERROR", e);
  }
}

export async function validateOrgDoamin(domain: string): Promise<void> {
  try {
    const res = await authInstance.post("/organization/check/domain", {
      url: domain,
    });
    return res.data;
  } catch (e) {
    if (e instanceof PXAPIError) throw e;
    throw new PXAPIError("Error occurred", "ERROR", e);
  }
}

export async function checkOrgTxtRecord(domain: string): Promise<void> {
  try {
    const res = await authInstance.post("/organization/check/domaintxt", {
      url: domain,
    });
    return res.data;
  } catch (e) {
    if (e instanceof PXAPIError) throw e;
    throw new PXAPIError("Error occurred", "ERROR", e);
  }
}

export async function getOrganizationByID(
  key: string,
  { orgId }: { orgId: string | "empty" }
) {
  const PATH = `/organization/${orgId}`;
  try {
    const res = await authInstance.get(PATH, {
      headers: { [HEADERS.ORG_ID]: orgId === "empty" ? undefined : orgId },
    });
    const apiRes = res.data;
    return apiRes.data as UserOrgMapping;
  } catch (e) {
    if (e instanceof PXAPIError) throw e;
    throw new PXAPIError("Error occured", "ERROR", { error: e });
  }
}

export async function getOrganizationDocByID(
  key: string,
  { orgId }: { orgId: string }
) {
  const PATH = `/organization/doc/${orgId}`;
  try {
    const res = await authInstance.get(PATH);
    const apiRes = res.data;
    return apiRes.data as OrgDocument;
  } catch (e) {
    if (e instanceof PXAPIError) throw e;
    throw new PXAPIError("Error occured", "ERROR", { error: e });
  }
}

export async function deleteOrganization(orgId: string) {
  const PATH = `/organization/${orgId}`;
  try {
    await authInstance.delete(PATH);
  } catch (e) {
    if (e instanceof PXAPIError) throw e;
    throw new PXAPIError("Error occured", "ERROR", { error: e });
  }
}

export async function deleteProject(projId: string) {
  const PATH = `/organization/project/${projId}`;
  try {
    await authInstance.delete(PATH);
  } catch (e) {
    if (e instanceof PXAPIError) throw e;
    throw new PXAPIError("Error occured", "ERROR", { error: e });
  }
}

export interface OrgDetailedMapping
  extends UserOrgMapping,
    Omit<OrgDocument, keyof UserOrgMapping> {}
export async function getAllOrganizationsDetails() {
  const PATH = "organization/view/list";
  try {
    const res = await authInstance.get(PATH, {});
    const apiRes = res.data;
    return apiRes.data as OrgDetailedMapping[];
  } catch (e) {
    if (e instanceof PXAPIError) throw e;
    throw new PXAPIError("Error occured", "ERROR", { error: e });
  }
}

export interface ProjectDetailedMapping
  extends UserProjectMapping,
    Omit<ProjectDocument, keyof UserProjectMapping> {}
export async function getAllProjectDetails(
  _key: string,
  { orgId }: { orgId: string }
) {
  const PATH = `organization/project/view/list/${orgId}`;
  try {
    const res = await authInstance.get(PATH, {});
    const apiRes = res.data;
    return apiRes.data as ProjectDetailedMapping[];
  } catch (e) {
    if (e instanceof PXAPIError) throw e;
    throw new PXAPIError("Error occured", "ERROR", { error: e });
  }
}

export async function createProject(data: ProjectCreateData) {
  try {
    const res = await authInstance.post(
      "/organization/project/create",
      data,
      {}
    );
    return res.data;
  } catch (e) {
    if (e instanceof PXAPIError) throw e;
    throw new PXAPIError("Error occured", "ERROR", e);
  }
}

interface ProjectEditData
  extends Pick<ProjectDocument, "name" | "description" | "organization_id"> {
  project_id: string;
}
export async function updateProject(data: ProjectEditData): Promise<unknown> {
  try {
    const res = await authInstance.put("/organization/project/edit", data, {});
    return res.data;
  } catch (e) {
    if (e instanceof PXAPIError) throw e;
    throw new PXAPIError("Error occured", "ERROR", e);
  }
}

/**
 *
 * @param key
 * @param projectId ProjectId or "empty" to let server select one
 * * throws `AUTH_ERRORS.EMPTY_PROJECTS`
 */
export async function getProjectByID(
  key: string,
  { orgId, projectId }: { orgId: string; projectId: string }
) {
  const PATH = `/organization/project/${orgId}/${projectId}`;
  try {
    const res = await authInstance.get(PATH, {
      headers: { [HEADERS.ORG_ID]: orgId, [HEADERS.PROJECT_ID]: projectId },
    });
    const apiRes = res.data;
    if (apiRes.data.organization_id !== orgId) {
      throw new PXAPIError("Invalid project", "INVALID_PROJECT", {
        code: AUTH_ERRORS.INVALID_PROJECT_ID,
      });
    }
    return apiRes.data as UserProjectMapping;
  } catch (e) {
    if (e instanceof PXAPIError) throw e;
    throw new PXAPIError("Error occured", "ERROR", { error: e });
  }
}

export async function getProjectDocByID(
  key: string,
  { orgId, projectId }: { orgId: string; projectId: string }
) {
  const PATH = `/organization/project/doc/${projectId}`;
  try {
    const res = await authInstance.get(PATH);
    const apiRes = res.data;
    return apiRes.data as ProjectDocument;
  } catch (e) {
    if (e instanceof PXAPIError) throw e;
    throw new PXAPIError("Error occured", "ERROR", { error: e });
  }
}

/**
 * Default organization role collections
 */
export async function getOrganizationRoles() {
  const PATH = `role/organization/list`;
  try {
    const res = await authInstance.get(PATH);
    const apiRes = res.data;
    return apiRes.data as RoleCollectionDocument[];
  } catch (e) {
    if (e instanceof PXAPIError) throw e;
    throw new PXAPIError("Error occured", "ERROR", { error: e });
  }
}
/**
 * Default organization role collections
 */
export async function getProjectRoles() {
  const PATH = `role/project/list`;
  try {
    const res = await authInstance.get(PATH);
    const apiRes = res.data;
    return apiRes.data as RoleCollectionDocument[];
  } catch (e) {
    if (e instanceof PXAPIError) throw e;
    throw new PXAPIError("Error occured", "ERROR", { error: e });
  }
}

export async function getOrganizationsList() {
  const PATH = `/organization/list`;
  try {
    const res = await authInstance.get(PATH, {
      params: { organization_ids: [] },
    });
    const apiRes = res.data;
    return apiRes.data as UserOrgMapping[];
  } catch (e) {
    if (e instanceof PXAPIError) throw e;
    throw new PXAPIError("Error occured", "ERROR", { error: e });
  }
}

export interface ProjectData {
  projectId: string;
  projectName: string;
  roles: UserProjectMapping["roles"];
  isProjectOwner: boolean;
}
export async function getProjectList(
  key: string,
  { orgId }: { orgId: string }
) {
  const PATH = `/organization/project/list/${orgId}`;
  try {
    const res = await authInstance.get(PATH);
    const apiRes = res.data;
    return apiRes.data as ProjectData[];
  } catch (e) {
    if (e instanceof PXAPIError) throw e;
    throw new PXAPIError("Error occured", "ERROR", { error: e });
  }
}

/**
 * Organization users
 */

export async function getOrgUsersList(
  _key: string,
  { orgId }: { orgId: string }
) {
  const PATH = `organization/user/list/${orgId}`;
  try {
    const res = await authInstance.get(PATH);
    const apiRes = res.data;
    return removeServiceAccounts(apiRes.data as UserOrgMapping[]);
  } catch (e) {
    if (e instanceof PXAPIError) throw e;
    throw new PXAPIError("Error occured", "ERROR", { error: e });
  }
}

export interface OrgUserAddData {
  user_email: string;
  organization_id: string;
  roles: Array<{
    collection_id: string;
    roles_id: Array<string>;
  }>;
  custom_roles: string[];
}
export async function addOrgUser(data: OrgUserAddData) {
  const PATH = "/role/organization/add/user";
  try {
    const res = await authInstance.post(PATH, data, {
      headers: { [HEADERS.ORG_ID]: data.organization_id },
    });
    return res.data;
  } catch (e) {
    if (e instanceof PXAPIError) throw e;
    throw new PXAPIError("Error occured", "ERROR", { error: e });
  }
}

export async function updateOrgUser(data: OrgUserEditData) {
  const PATH = "/role/organization/update/user";
  try {
    const res = await authInstance.put(PATH, data, {});
    return res.data;
  } catch (e) {
    if (e instanceof PXAPIError) throw e;
    throw new PXAPIError("Error occured", "ERROR", { error: e });
  }
}

export async function removeOrgUser({ userId, orgId }: OrgUserRemoveData) {
  const PATH = `/organization/user/${userId}/${orgId}`;
  try {
    const res = await authInstance.delete(PATH);
    return res.data;
  } catch (e) {
    if (e instanceof PXAPIError) throw e;
    throw new PXAPIError("Error occured", "ERROR", { error: e });
  }
}

export async function removePendingUser({
  email,
  orgId,
}: PendingUserRemoveData) {
  const PATH = `/organization/user/pending/${email}/${orgId}`;
  try {
    const res = await authInstance.delete(PATH);
    return res.data;
  } catch (e) {
    if (e instanceof PXAPIError) throw e;
    throw new PXAPIError("Error occured", "ERROR", { error: e });
  }
}

/**
 * Project users
 */

export async function getProjectUsersList(
  _key: string,
  { orgId, projectId }: { orgId: string; projectId: string }
) {
  const PATH = `organization/project/users/${orgId}/${projectId}`;
  try {
    const res = await authInstance.get(PATH, {
      // on project create
      headers: { [HEADERS.PROJECT_ID]: projectId },
    });
    const apiRes = res.data;
    return removeServiceAccounts(apiRes.data as UserProjectMapping[]);
  } catch (e) {
    if (e instanceof PXAPIError) throw e;
    throw new PXAPIError("Error occured", "ERROR", { error: e });
  }
}

export async function addProjectUser(data: ProjectUserAddData) {
  const PATH = "/role/project/add/user";
  try {
    const res = await authInstance.post(PATH, data, {
      headers: { [HEADERS.PROJECT_ID]: data.project_id },
    });
    return res.data;
  } catch (e) {
    if (e instanceof PXAPIError) throw e;
    throw new PXAPIError("Error occured", "ERROR", { error: e });
  }
}

export async function updateProjectUser(data: ProjectUserEditData) {
  const PATH = "/role/project/update/user";
  try {
    const res = await authInstance.put(PATH, data, {});
    return res.data;
  } catch (e) {
    if (e instanceof PXAPIError) throw e;
    throw new PXAPIError("Error occured", "ERROR", { error: e });
  }
}

export async function removeProjectUser({
  userId,
  orgId,
  projectId,
}: ProjectUserRemoveData) {
  const PATH = `/organization/project/user/${userId}/${orgId}/${projectId}`;
  try {
    const res = await authInstance.delete(PATH);
    return res.data;
  } catch (e) {
    if (e instanceof PXAPIError) throw e;
    throw new PXAPIError("Error occured", "ERROR", { error: e });
  }
}

/**
 * Custom roles
 */

export async function addOrgCustomRole(data: OrgCustomRoleAddData) {
  const PATH = "/customroles/organization/create";
  try {
    const res = await authInstance.post(PATH, data, {});
    return res.data;
  } catch (e) {
    if (e instanceof PXAPIError) throw e;
    throw new PXAPIError("Error occured", "ERROR", { error: e });
  }
}

export async function updateOrgCustomRole(data: OrgCustomRoleEditData) {
  const PATH = "/customroles/organization/update";
  try {
    const res = await authInstance.put(PATH, data, {});
    return res.data;
  } catch (e) {
    if (e instanceof PXAPIError) throw e;
    throw new PXAPIError("Error occured", "ERROR", { error: e });
  }
}

export async function addProjectCustomRole(data: ProjectCustomRoleAddData) {
  const PATH = "/customroles/project/create";
  try {
    const res = await authInstance.post(PATH, data, {});
    return res.data;
  } catch (e) {
    if (e instanceof PXAPIError) throw e;
    throw new PXAPIError("Error occured", "ERROR", { error: e });
  }
}

export async function updateProjectCustomRole(data: ProjectCustomRoleEditData) {
  const PATH = "/customroles/project/update";
  try {
    const res = await authInstance.put(PATH, data, {});
    return res.data;
  } catch (e) {
    if (e instanceof PXAPIError) throw e;
    throw new PXAPIError("Error occured", "ERROR", { error: e });
  }
}

export async function getOrgCustomRoles(
  key: string,
  { orgId }: { orgId: string }
) {
  const PATH = `/customroles/organization/list/${orgId}`;
  try {
    const res = await authInstance.get(PATH, {
      headers: { [HEADERS.ORG_ID]: orgId },
    });
    const apiRes = res.data;
    return apiRes.data as CustomRoleDocument[];
  } catch (e) {
    if (e instanceof PXAPIError) throw e;
    throw new PXAPIError("Error occured", "ERROR", { error: e });
  }
}

export async function getProjectCustomRoles(
  key: string,
  { orgId, projectId }: { orgId: string; projectId: string }
) {
  const PATH = `/customroles/project/list/${orgId}/${projectId}`;
  try {
    const res = await authInstance.get(PATH, {
      headers: { [HEADERS.ORG_ID]: orgId, [HEADERS.PROJECT_ID]: projectId },
    });
    const apiRes = res.data;
    return apiRes.data as CustomRoleDocument[];
  } catch (e) {
    if (e instanceof PXAPIError) throw e;
    throw new PXAPIError("Error occured", "ERROR", { error: e });
  }
}

//env users

export async function getEnvironmentMappings(
  key: string,
  orgId: string,
  projectId: string
) {
  const PATH = `/env/mappings/${orgId}/${projectId}`;
  try {
    const res = await authInstance.get(PATH);
    return res.data.data as UserEnvMapping[];
  } catch (e) {
    if (e instanceof PXAPIError) throw e;
    throw new PXAPIError("Error occured", "ERROR", { error: e });
  }
}

export async function getEnvironmentUsers(
  key: string,
  orgId: string,
  projectId: string,
  envId: string
) {
  const PATH = `/env/users/${orgId}/${projectId}/${envId}`;
  try {
    const res = await authInstance.get(PATH);
    const apiRes = res.data;
    return apiRes.data as UserEnvMapping[];
  } catch (e) {
    if (e instanceof PXAPIError) throw e;
    throw new PXAPIError("Error occured", "ERROR", { error: e });
  }
}

export async function addEnvironmentUser(data: EnvUserAddData) {
  const PATH = "/env/user/add";
  try {
    const res = await authInstance.post(PATH, data);
    return res.data;
  } catch (e) {
    if (e instanceof PXAPIError) throw e;
    throw new PXAPIError("Error occured", "ERROR", { error: e });
  }
}

export async function updateEnvironmentUser(data: EnvUserAddData) {
  const PATH = "/env/user/update";
  try {
    const res = await authInstance.put(PATH, data);
    return res.data;
  } catch (e) {
    if (e instanceof PXAPIError) throw e;
    throw new PXAPIError("Error occured", "ERROR", { error: e });
  }
}
interface RemoveUserData {
  envId: string;
  organizationId: string;
  projectId: string;
  userId: string;
}
export async function removeEnvironmentUser(removeUserData: RemoveUserData) {
  const PATH = `/env/user/remove`;
  const data = {
    env_id: removeUserData.envId,
    organization_id: removeUserData.organizationId,
    project_id: removeUserData.projectId,
    user_id: removeUserData.userId,
  };
  try {
    const res = await authInstance.put(PATH, data);
    return res.data.data as unknown;
  } catch (e) {
    if (e instanceof PXAPIError) throw e;
    throw new PXAPIError("Error occured", "ERROR", { error: e });
  }
}

export async function enableGCPServices(projectId: string) {
  const PATH = `/organization/project/gcp/init`;
  try {
    const res = await authInstance.post(PATH, { project_id: projectId });
    return res.data.data as unknown;
  } catch (e) {
    if (e instanceof PXAPIError) throw e;
    throw new PXAPIError("Error occured", "ERROR", { error: e });
  }
}

/**
 * @deprecated
 */
export async function enablePlatformerServices(projectId: string) {
  const PATH = `/organization/project/plat/init`;
  try {
    const res = await authInstance.post(PATH, { project_id: projectId });
    return res.data.data as unknown;
  } catch (e) {
    if (e instanceof PXAPIError) throw e;
    throw new PXAPIError("Error occured", "ERROR", { error: e });
  }
}

export interface PrivateVaultInitData {
  project_id: string;
  role_id: string;
  secret_id: string;
  access_url: string;
  is_private_conn: boolean;
  cluster_id: string;
  app_role_name: string;
}
export async function initPrivateVault(data: PrivateVaultInitData) {
  const PATH = `/organization/project/private/vault`;
  try {
    const res = await authInstance.post(PATH, data);
    return res.data.data as unknown;
  } catch (e) {
    if (e instanceof PXAPIError) throw e;
    throw new PXAPIError("Error occured", "ERROR", { error: e });
  }
}

export async function getUserMetadata() {
  const PATH = `/organization/user/metadata`;
  try {
    const res = await authInstance.get(PATH);
    const apiRes = res.data;
    return apiRes.data as NetPromoterMetadata;
  } catch (e) {
    if (e instanceof PXAPIError) throw e;
    throw new PXAPIError("Error occured", "ERROR", { error: e });
  }
}
export async function updateUserMetdata(data: NetPromoterMetadata) {
  const PATH = "/organization/user/metadata";
  const dataTosend = { metadata: { ...data } };
  try {
    const res = await authInstance.put(PATH, dataTosend);
    return res.data;
  } catch (e) {
    if (e instanceof PXAPIError) throw e;
    throw new PXAPIError("Error occured", "ERROR", { error: e });
  }
}
export interface NetPromoterMetadata {
  ratings?: Array<{ key: string; rating: number | null; timestamp: number }>;
  userAgreement?: {
    isAgree: boolean | null;
    timestamp: number;
    version: string;
    ipAddress: string | null;
  };
}
