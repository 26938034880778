import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { createCtx } from "utls/createReactContext";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const [Provider, usePageLoader] = createCtx<(v: boolean) => void>(
  "PageLoaderContext"
);

/**
 * Render loader component in a common parent,
 *  to prevent resetting circular animation
 */
export function PageLoaderProvider({ children }: React.PropsWithChildren<{}>) {
  const [isActive, setIsActive] = useState(true);
  const classes = useStyles();
  return (
    <Provider value={setIsActive}>
      <Backdrop
        className={classes.backdrop}
        open={isActive}
        transitionDuration={0}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {children}
    </Provider>
  );
}

/**
 * Any component that needs to display loader animation
 *  can render `PageLoader` instead of its content.
 */
export function PageLoader() {
  const setIsActive = usePageLoader();
  useEffect(() => {
    setIsActive(true);
    return () => setIsActive(false);
  }, [setIsActive]);
  return null;
}

export { usePageLoader };
