import { Button, Typography } from "@material-ui/core";
import LogoutIcon from "@material-ui/icons/ExitToApp";
import RefreshIcon from "@material-ui/icons/Refresh";
import UpdateIcon from "@material-ui/icons/Update";
import AlertImageCard from "components/AlertImageCard/AlertImageCard";
import { PageLoader } from "components/PageLoader/PageLoader";
import { useGetConnectivity } from "containers/ErrorHandlers/useGetConnectivity";
import { useVersionUpdateStatus } from "modules/VersionUpdater/useVersionUpdateStatus";
import React from "react";
import {
  PXERR_CONFIG_NOT_AVAILABLE,
  PXERR_FIXED_ORG_ID_REQUIRED,
  PXERR_REJECTED_SIGNUP_ATTEMPT,
  PXERR_USER_INIT_FAILED,
} from "services/app_errors";
import { authenticatedActions } from "services/auth/authenticated_actions";
import { BROWSER } from "services/browser_info";
import FailureContainer from "./FailureContainer";
import "./FailureContainer.scss";

/**
 * Rendered in AUTH_STATES.CRITICAL_ERROR
 * Auth failures including
 * * failure to load config
 * * user document not available
 * ( shouldn't happen, delay between google sign in & doc creation is handled by server, & retries )
 * * user document fetch timeout
 * * firebase error ( eg: incognito mode , probably won't reach here)
 * Options available
 * * reload window
 * * might be stuck with a login flow bug
 * ( if service worker active, should allow user to update to a  newer version of app, else reloading is sufficient )
 * //TODO allow users to cleanup login state, another button?
 */
export function AuthFailureHandler({ error }: { error: Error }) {
  const connectivityQuery = useGetConnectivity();
  const { hasUpdate, applyUpdate, isActivating } = useVersionUpdateStatus();
  function cleanUpFirebaseAuthNReload() {
    authenticatedActions.clearAuthState().finally(BROWSER.reload);
  }
  /**
   * since config file isn't cached, this is a n/w issue
   */
  const isConfigFailure = PXERR_CONFIG_NOT_AVAILABLE === error?.message;
  const isSignupRejection = PXERR_REJECTED_SIGNUP_ATTEMPT === error?.message;
  if (isActivating) return <PageLoader />;
  if (isSignupRejection) {
    return (
      <FailureContainer>
        <AlertImageCard
          btnIcon={<RefreshIcon />}
          cardImgClass="server-down"
          severity="info"
          message="Platformer is no longer allowing new signups."
          btnText="Reload"
          onBtnClick={BROWSER.reload}
        />
      </FailureContainer>
    );
  }
  if (isConfigFailure || connectivityQuery.data === "not-connected") {
    return (
      <FailureContainer>
        <AlertImageCard
          btnIcon={<RefreshIcon />}
          cardImgClass="server-down"
          severity="info"
          secondaryMessage={` Checking connectivity: ${
            connectivityQuery.data || connectivityQuery.status
          }`}
          message="Unable to start the application. This may be due to a network connectivity issue."
          btnText="Reload"
          onBtnClick={BROWSER.reload}
        />
      </FailureContainer>
    );
  }
  const missingFixedOrgId = PXERR_FIXED_ORG_ID_REQUIRED === error?.message;
  if (missingFixedOrgId) {
    return (
      <FailureContainer>
        <AlertImageCard
          btnIcon={<RefreshIcon />}
          cardImgClass="server-down"
          severity="info"
          message="Unable to start the application. Organization Id is not specified in the application configuration."
          btnText="Reload"
          onBtnClick={BROWSER.reload}
        />
      </FailureContainer>
    );
  }
  const isUserInitFailure = PXERR_USER_INIT_FAILED === error?.message;
  if (isUserInitFailure) {
    const btns = (
      <div className="btn-row flex-center mt-4">
        <div className="btn-grid">
          <Button
            startIcon={<RefreshIcon />}
            variant="outlined"
            onClick={BROWSER.reload}
            color="primary"
          >
            Reload
          </Button>
        </div>
        <div className="btn-grid">
          <Button
            startIcon={<LogoutIcon />}
            variant="outlined"
            onClick={cleanUpFirebaseAuthNReload}
            color="primary"
          >
            Logout and reload
          </Button>
        </div>
      </div>
    );
    return (
      <FailureContainer>
        <AlertImageCard
          btnIcon={<RefreshIcon />}
          cardImgClass="server-down"
          severity="info"
          secondaryMessage={` Checking connectivity: ${
            connectivityQuery.data || connectivityQuery.status
          }`}
          message="Unable to continue with login. This may be due to a network connectivity issue."
          actions={btns}
        />
        <Typography variant="body1" className="secondary-msg-txt mt-3">
          If the issue persists, please contact support.
        </Typography>
      </FailureContainer>
    );
  }
  return (
    <FailureContainer>
      {hasUpdate ? (
        <>
          <AlertImageCard
            btnIcon={<UpdateIcon />}
            severity="info"
            cardImgClass="new-version"
            secondaryMessage=""
            message="Unable to start the application. This may be due to a network connectivity issue or some other error. Try updating to the latest
          version."
            btnText="Update"
            onBtnClick={applyUpdate}
          />
        </>
      ) : (
        <AlertImageCard
          btnIcon={<RefreshIcon />}
          cardImgClass="complex-error"
          severity="info"
          secondaryMessage=""
          message="Unable to continue with login. This may be due to a network connectivity issue or some other error."
          btnText="Reload"
          onBtnClick={BROWSER.reload}
        />
      )}
      <Typography variant="body1" className="secondary-msg-txt mt-3">
        If the issue persists, please contact support.
      </Typography>
    </FailureContainer>
  );
}
