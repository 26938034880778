/**
 * any failure load config.js
 * * syntax error
 * * script error
 * * n/w error
 */
export const PXERR_CONFIG_NOT_AVAILABLE = "APP_CONFIG_NOT_AVAILABLE";
/**
 * Failure to get user doc
 * * n/w error , server error
 * * user doc not available
 */
export const PXERR_USER_INIT_FAILED = "FAILED_INIT_USER_DATA";
/**
 * New signups disabled on server
 * * no user doc available on signin
 */
export const PXERR_REJECTED_SIGNUP_ATTEMPT = "REJECTED_SIGNUP_ATTEMPT";
/**
 * Onpremise configuration is missing organization id
 */
export const PXERR_FIXED_ORG_ID_REQUIRED = "FIXED_ORG_ID_NOT_SPECIFIED";
