import { PageLoader } from "components/PageLoader/PageLoader";
import { AuthFailureHandler } from "containers/ErrorHandlers/AuthFailure";
import React, { useEffect, useState } from "react";
import { authMachineService } from "services/auth/authMachine/authMachine";
import { AUTH_STATES } from "services/auth/authMachine/interfaces";

interface AuthHandlerProps {}

const loadingStates = [
  AUTH_STATES.PAGE_LOAD,
  AUTH_STATES.CHECK_PRV_SESSION,
  AUTH_STATES.INIT_USER_DATA,
  AUTH_STATES.LOGGING_OUT,
];

export function AuthHandler(props: React.PropsWithChildren<AuthHandlerProps>) {
  const { children } = props;
  const authState = useAuthState();
  if (authState.matches(AUTH_STATES.CRITICAL_ERROR)) {
    return <AuthFailureHandler error={authState.context.criticalError!} />;
  }
  if (loadingStates.some(authState.matches)) {
    return <PageLoader />;
  }
  return <>{children}</>;
}

function useAuthState() {
  const [authState, setAuthState] = useState(authMachineService.initialState);
  useEffect(() => {
    authMachineService.onTransition(setAuthState);
    return () => {
      authMachineService.off(setAuthState);
    };
  }, []);
  return authState;
}
