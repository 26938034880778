import { OnPremUser, updateLastLogin } from "api/auth/auth_endpoints";
import { wbRegistration } from "modules/VersionUpdater/wb";
import { queryClientV3X } from "queries/queryConfig";
import { authenticatedActions } from "services/auth/authenticated_actions";
import { authMainActions } from "services/auth/auth_main_actions";
import { ConfigService } from "services/config/configService";
import { ENV } from "services/env_variables";
import { FbUser, isFirebaseUser } from "services/firebase/FbUser";
import { GA } from "services/firebase/ga";
import { Intercom } from "services/intercom";
import { TabSync } from "services/tabSync";
import { UserDocument, UserId } from "shared/interfaces/document_interfaces";
import { orgStore } from "store/orgStore";
import { projectStore } from "store/projectStore";
import { userStore } from "store/userStore";
import { waitFor } from "utls/async_utils";
import { assign, interpret, Machine } from "xstate";
import {
  getInitialOrgProjectIds,
  INITIAL_PATH,
  persistSelection,
  redirectToCliLoginPage,
  redirectToLoginRegister,
} from "../authUtils";
import { authIdleActions } from "../auth_idle_actions";
import { CLI_LOGIN_PATH, EMAIL_VERIFIED_PATH } from "../auth_routes";
import {
  fetchCurrentUser,
  getOrgRolesAndUserMapping,
  getProjectRolesAndUserMapping,
} from "../contextMachine/auth_queries";
import { switchOrgProjectRoute } from "../contextMachine/routeUtls";
import { PXERR_REJECTED_SIGNUP_ATTEMPT } from "./../../app_errors";
import type { FirebaseAuthIdleActions } from "./../../firebase/fb_auth_idle_actions";
import {
  AuthMachineContext,
  AuthMachineEvent,
  AuthMachineSchema,
  AUTH_STATES,
  LoginWithProvider,
  OrgProjectChangedEvent,
} from "./interfaces";

const initialContext: AuthMachineContext = {
  initialPath: INITIAL_PATH,
  userId: null,
  initialOrgId: null,
  initialProjId: null,
  criticalError: null,
};

const authMachine = Machine<
  AuthMachineContext,
  AuthMachineSchema,
  AuthMachineEvent
>(
  {
    id: "auth",
    initial: AUTH_STATES.PAGE_LOAD,
    context: initialContext,
    states: {
      [AUTH_STATES.PAGE_LOAD]: {
        invoke: {
          id: "loadAppConfig",
          src: async () => {
            window.performance.mark("entered_state_page_load");
            ConfigService.init();
            authMainActions.init();
            window.performance.mark("initialized_firebase");
            TabSync.init();
          },
          onDone: [
            {
              target: AUTH_STATES.AWAIT_LOGIN_REGISTER,
              cond: "emailVerification",
            },
            { target: AUTH_STATES.CHECK_PRV_SESSION },
          ],
          onError: {
            target: AUTH_STATES.CRITICAL_ERROR,
            actions: assign((_, e) => ({ criticalError: e.data })),
          },
        },
      },
      [AUTH_STATES.CHECK_PRV_SESSION]: {
        invoke: {
          id: "getFirebaseUser",
          src: async () => {
            window.performance.mark("get_user_on_load_start");
            const user = await authMainActions.getLoggedInUserOnPageLoad();
            window.performance.mark("get_user_on_load_end");
            return user;
          },
          onDone: [
            { target: AUTH_STATES.INIT_USER_DATA, cond: "hasUser" },
            { target: AUTH_STATES.AWAIT_LOGIN_REGISTER },
          ],
          onError: {
            target: AUTH_STATES.CRITICAL_ERROR,
            actions: assign((_, e) => ({ criticalError: e.data })),
          },
        },
      },
      [AUTH_STATES.AWAIT_LOGIN_REGISTER]: {
        on: {
          LOGIN_WITH_EMAIL_SUCCESS: {
            target: AUTH_STATES.INIT_USER_DATA,
            cond: "hasUser",
          },
          LOGIN_TAB_SYNC: {
            actions: (_) => {
              window.location.reload();
            },
          },
          INVALID_ROUTE_DETECTED: {
            target: AUTH_STATES.CHECK_PRV_SESSION,
          },
        },
        entry: [
          "redirectToLoginRegister",
          (_) => {
            TabSync.notifyOtherTabs("LOGGED_OUT_MSG");
            if (wbRegistration?.registered) {
              wbRegistration.skipWaiting(true);
            }
          },
        ],
        initial: "idle",
        states: {
          idle: {
            on: {
              LOGIN_WITH_PROVIDER: [
                { target: "signInWithPopup", cond: "openedFromCLI" },
                { actions: ["loginWithProvider"] },
              ],
            },
          },
          signInWithPopup: {
            invoke: {
              id: "signInWithPopup",
              src: async (_, event) => {
                if (process.env.REACT_APP_AUTH_ENV !== "ON_PREM") {
                  return (authIdleActions as FirebaseAuthIdleActions).signInWithPopUp(
                    (event as any).provider
                  );
                }
                return null;
              },
              onDone: [
                { target: `#${AUTH_STATES.INIT_USER_DATA}`, cond: "hasUser" },
                { target: "idle" },
              ],
              onError: { target: "idle" },
            },
          },
        },
      },
      [AUTH_STATES.CLI_LOGIN]: {
        entry: [redirectToCliLoginPage],
        on: {
          INVALID_ROUTE_DETECTED: { target: AUTH_STATES.LOGGED_IN },
        },
      },
      [AUTH_STATES.INIT_USER_DATA]: {
        id: AUTH_STATES.INIT_USER_DATA,
        entry: [
          "setUserId",
          "setUserIdInStore",
          "loadPreviousOrgProjectIds",
          "prefetchOrg",
          "prefetchProject",
          updateLastLogin,
          "onLogin",
          (_) => {
            TabSync.notifyOtherTabs("LOGGED_IN_MSG");
          },
        ],
        invoke: {
          id: "load-user",
          src: async () => {
            window.performance.mark("fetch_user_doc_start");
            const user = await fetchCurrentUser();
            window.performance.mark("fetch_user_doc_end");
            return user;
          },
          onDone: [
            {
              target: AUTH_STATES.CLI_LOGIN,
              cond: "openedFromCLI",
              actions: "setUserDocumentInStore",
            },
            {
              target: AUTH_STATES.LOGGED_IN,
              actions: "setUserDocumentInStore", //TODO Update intercom
            },
          ],
          onError: [
            {
              target: AUTH_STATES.CRITICAL_ERROR,
              actions: [
                "setUserError",
                assign((_, e) => ({ criticalError: e.data })),
              ],
            },
          ],
        },
        on: {},
      },
      [AUTH_STATES.LOGGED_IN]: {
        entry: [
          (ctx: AuthMachineContext) => {
            switchOrgProjectRoute(ctx.initialOrgId, ctx.initialProjId);
            window.performance.mark("entered_state_logged_in");
          },
        ],
        on: {
          LOGOUT: {
            target: AUTH_STATES.LOGGING_OUT,
          },
          LOGOUT_TAB_SYNC: {
            actions: (_) => {
              window.location.reload();
            },
          },
          ORG_PROJECT_CHANGE: {
            actions: ["persistOrgProjectSelection"],
          },
          INVALID_ROUTE_DETECTED: {
            actions: [
              (ctx) => {
                switchOrgProjectRoute(ctx.initialOrgId, ctx.initialProjId);
              },
            ],
          },
        },
      },
      [AUTH_STATES.LOGGING_OUT]: {
        invoke: {
          id: "logout",
          src: "cleanUpApp",
          onDone: {
            target: AUTH_STATES.AWAIT_LOGIN_REGISTER,
            actions: () => {
              TabSync.notifyOtherTabs("LOGGED_OUT_MSG");
            },
          },
          onError: { target: AUTH_STATES.AWAIT_LOGIN_REGISTER },
        },
        exit: ["onLogout"],
      },
      [AUTH_STATES.CRITICAL_ERROR]: {
        entry: "onCriticalError",
      },
    },
  },
  {
    services: {
      async cleanUpApp() {
        projectStore.getState().reset();
        orgStore.getState().reset();
        userStore.getState().reset();
        queryClientV3X.clear();
        await authenticatedActions.clearAuthState();
        await waitFor(200);
      },
    },
    guards: {
      hasUser: (context: AuthMachineContext, event: AuthMachineEvent) => {
        return !!(event as any).data;
      },
      openedFromCLI: (ctx, event) => {
        return ctx.initialPath?.startsWith(CLI_LOGIN_PATH) || false;
      },
      emailVerification: (ctx, event) => {
        return ctx.initialPath?.startsWith(EMAIL_VERIFIED_PATH) || false;
      },
    },
    actions: {
      onCriticalError(ctx) {
        const error = ctx.criticalError;
        if (
          error instanceof Error &&
          error.message === PXERR_REJECTED_SIGNUP_ATTEMPT
        ) {
          authenticatedActions.clearAuthState();
        }
      },
      setUserId: assign({
        userId: (context, event) => {
          const user = (event as any).data;
          if (isFirebaseUser(user)) {
            const { uid } = user;
            return uid;
          }
          const { id } = user as OnPremUser;
          return id;
        },
      }),
      loadPreviousOrgProjectIds: assign((ctx) => {
        const { orgId, projectId } = getInitialOrgProjectIds(
          ctx.userId as UserId
        );
        return { initialOrgId: orgId, initialProjId: projectId };
      }),
      prefetchOrg(ctx) {
        getOrgRolesAndUserMapping(ctx.initialOrgId).catch(() => {
          /** ignore error */
        });
      },
      prefetchProject(ctx) {
        if (!ctx.initialOrgId) return;
        getProjectRolesAndUserMapping(
          ctx.initialOrgId,
          ctx.initialProjId
        ).catch(() => {
          /** ignore error */
        });
      },
      setUserIdInStore(ctx) {
        userStore.getState().setUser(ctx.userId as UserId);
      },
      setUserDocumentInStore(_, e: any) {
        userStore.getState().updateDocument(e.data as UserDocument);
      },
      setUserError() {
        userStore.getState().setValidity("error");
      },
      loginWithProvider: async (context, event) => {
        if (process.env.REACT_APP_AUTH_ENV !== "ON_PREM") {
          const { provider } = event as LoginWithProvider;
          await (authIdleActions as FirebaseAuthIdleActions).signInWithProvider(
            provider
          );
        }
      },
      redirectToLoginRegister,
      redirectToCliLoginPage,
      onLogin,
      onLogout() {
        Intercom.logout();
      },
      persistOrgProjectSelection(ctx, event) {
        const userId = ctx.userId as UserId;
        const { orgId, projectId } = event as OrgProjectChangedEvent;
        persistSelection({ userId, orgId, projectId });
      },
    },
  }
);

async function onLogin(context: AuthMachineContext, event: AuthMachineEvent) {
  if (ENV.isOnPrem) return;
  const { displayName, email, uid } = (event as any).data as FbUser;
  Intercom.login({
    name: displayName || uid.substring(0, 8),
    user_id: uid,
    email: email || undefined,
  });
  GA.onUserInit({ uid });
}

export const authMachineService = interpret(authMachine, {
  devTools: process.env.NODE_ENV === "development",
});

if (process.env.NODE_ENV === "development") {
  (window as any).authMachine = authMachineService;
}
