import { OnPremUser } from "api/auth/auth_endpoints";
import { AuthenticatedActions } from "services/auth/auth_actions_interfaces";
import { firebaseAuthenticatedActions } from "services/firebase/fb_authenticated_actions";
import { FbUser } from "services/firebase/FbUser";
import { OnPremiseAuth } from "services/onpremise/on_premise_auth";

let authenticatedActions: AuthenticatedActions<OnPremUser | FbUser>;

if (process.env.REACT_APP_AUTH_ENV === "ON_PREM") {
  authenticatedActions = OnPremiseAuth;
} else {
  authenticatedActions = firebaseAuthenticatedActions;
}
export { authenticatedActions };
