/**
 * Queries requires for initial page  load
 */
export const CRITICAL_QUERIES = {
  /**
   * Required to move from page load animation to dashboard
   */
  USER_DOC: "current-user",
  /**
   * Required before rendering for pages marked as "org" or "project"
   */
  ORG_USER_MAPPING: "org-user-mapping",
  /**
   * Required before rendering for pages marked as "project"
   */
  PROJ_USER_MAPPING: "project-user-mapping",
  /**
   * Required to display/enable content with org rules
   */
  DEFAULT_ORG_ROLES: "org-roles",
  /**
   * Required to display/enable content with org rules
   */
  CUSTOM_ORG_ROLES: "custom-org-roles",
  /**
   * Required to display/enable content with project rules,
   * when org rules don't match
   */
  DEFAULT_PROJ_ROLES: "project-roles",
  /**
   * Required to display/enable content with project rules,
   * when org rules don't match
   */
  CUSTOM_PROJ_ROLES: "custom-project-roles",
};
