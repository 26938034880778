import firebase from "firebase/app";
import { AuthMainActions } from "services/auth/auth_actions_interfaces";
import { ConfigService } from "services/config";
import { FbUser } from "services/firebase/FbUser";
import { GA } from "services/firebase/ga";
import { waitFor } from "utls/async_utils";

if (process.env.REACT_APP_AUTH_ENV !== "ON_PREM") {
  require("firebase/auth");
}

export const firebaseAuthMainActions = /* #__PURE__ */ new (class
  implements AuthMainActions<FbUser> {
  init = () => {
    firebase.initializeApp(ConfigService.CONFIG.FIREBASE);
    if (ConfigService.CONFIG.FIREBASE_EVENTS === "ENABLED") {
      initAnalytics().catch(() => {
        GA.stop();
      });
    }
  };
  getLoggedInUserOnPageLoad = async (): Promise<FbUser | null> => {
    return new Promise((resolve) => {
      const unsubscribe = firebase.auth().onAuthStateChanged(function (user) {
        resolve(user);
        unsubscribe();
      });
    });
  };
})();

async function initAnalytics() {
  await waitFor(20000);
  // static import adds 30KB to the main vendor chunk
  await import(/* webpackChunkName: "pxa" */ "firebase/analytics");
  const isSupported = await firebase.analytics.isSupported();
  if (isSupported) {
    const ga = firebase.analytics();
    GA.onLoad(ga);
  } else {
    GA.stop();
  }
}
