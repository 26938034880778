import {
  createMuiTheme,
  Theme as MuiTheme,
  ThemeOptions,
} from "@material-ui/core";
import overrides from "./overrides";
import palette from "./palette";
import typography from "./typography";

const baseTheme: ThemeOptions = {
  palette,
  typography: typography as any,
  overrides,
};

export const theme = createMuiTheme(baseTheme);

type MuiPalette = MuiTheme["palette"];
interface Palette extends MuiPalette {
  icon: string;
}
export interface Theme extends MuiTheme {
  palette: Palette;
}
