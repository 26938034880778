import { AuthAPI, AUTH_ERRORS } from "api/auth";
import { PXAPIError } from "api/responseInterceptor";
import { CRITICAL_QUERIES } from "queries/critical_queries";
import { queryCacheV2X } from "queries/queryConfig";
import { QueryConfigV2 } from "queries/useTypedQuery";
import {
  CustomRoleDocument,
  RoleCollectionDocument,
  UserOrgMapping,
  UserProjectMapping,
} from "shared/interfaces/document_interfaces";
import {
  PXERR_REJECTED_SIGNUP_ATTEMPT,
  PXERR_USER_INIT_FAILED,
} from "./../../app_errors";

export async function getOrgRolesAndUserMapping(orgId: string | null) {
  let { selectedOrgId, mappingDocPromise } = await fetchOrgDocument(orgId);
  const defaultRolesPromise = fetchOrgRoles();
  const customRolesPromise = fetchCustomOrgRoles(selectedOrgId);
  const [orgMapping, defaultOrgRoles, customOrgRoles] = await Promise.all([
    mappingDocPromise,
    defaultRolesPromise,
    customRolesPromise,
  ]);
  return { orgMapping, defaultOrgRoles, customOrgRoles };
}

const VALID_ORG_ERRORS = [
  AUTH_ERRORS.EMPTY_ORGANIZATIONS,
  AUTH_ERRORS.INVALID_ORGANIZATION_ID,
];
const orgOptions: QueryConfigV2<UserOrgMapping> = {
  retry(count, error) {
    const errCode = (error as PXAPIError).code;
    //prevent retry if not an api error/ network error
    if (VALID_ORG_ERRORS.includes(errCode || "INVALID")) {
      return false;
    }
    return count < 3;
  },
};
async function fetchOrgDocument(orgId: string | null) {
  let selectedOrgId = orgId || "empty";
  let mappingDocPromise = queryCacheV2X.fetchQuery(
    [CRITICAL_QUERIES.ORG_USER_MAPPING, { orgId: selectedOrgId }],
    AuthAPI.getOrganizationByID,
    orgOptions
  );
  if (selectedOrgId === "empty") {
    const res = await mappingDocPromise;
    selectedOrgId = res.organization_id;
    // assign data to a new query with actual org id
    mappingDocPromise = queryCacheV2X.fetchQuery(
      [CRITICAL_QUERIES.ORG_USER_MAPPING, { orgId: selectedOrgId }],
      AuthAPI.getOrganizationByID,
      { ...orgOptions, initialData: res }
    );
  }
  return { selectedOrgId, mappingDocPromise };
}

export function getCachedOrgRolesAndUserMapping(orgId: string) {
  const orgMapping = queryCacheV2X.getQueryData([
    CRITICAL_QUERIES.ORG_USER_MAPPING,
    { orgId },
  ]) as UserOrgMapping;
  const defaultOrgRoles = getOrgRolesFromQuery();
  const customOrgRoles = getCustomOrgRolesFromQuery(orgId);
  return { orgMapping, defaultOrgRoles, customOrgRoles };
}

export async function getProjectRolesAndUserMapping(
  orgId: string,
  projectId: string | null
) {
  let { selectedProjectId, mappingDocPromise } = await fetchProjectDocument(
    orgId,
    projectId
  );
  const defaultRolesPromise = fetchProjectRoles();
  const customRolesPromise = fetchCustomProjectRoles(orgId, selectedProjectId);
  const [
    projectMapping,
    defaultProjectRoles,
    customProjectRoles,
  ] = await Promise.all([
    mappingDocPromise,
    defaultRolesPromise,
    customRolesPromise,
  ]);
  return { projectMapping, defaultProjectRoles, customProjectRoles };
}

const VALID_PROJECT_ERRORS = [
  AUTH_ERRORS.EMPTY_PROJECTS,
  AUTH_ERRORS.INVALID_PROJECT_ID,
];
const projectOptions: QueryConfigV2<UserProjectMapping> = {
  retry(count, error) {
    const errCode = (error as PXAPIError).code;
    //prevent retry if not an api error/ network error
    if (VALID_PROJECT_ERRORS.includes(errCode || "INVALID")) {
      return false;
    }
    return count < 3;
  },
};
async function fetchProjectDocument(orgId: string, projectId: string | null) {
  let selectedProjectId = projectId || "empty";
  let mappingDocPromise = queryCacheV2X.fetchQuery(
    [
      CRITICAL_QUERIES.PROJ_USER_MAPPING,
      { orgId, projectId: selectedProjectId },
    ],
    AuthAPI.getProjectByID,
    projectOptions
  );
  if (selectedProjectId === "empty") {
    const res = await mappingDocPromise;
    selectedProjectId = res.project_id;
    // assign data to a new query with actual project id
    mappingDocPromise = queryCacheV2X.fetchQuery(
      [
        CRITICAL_QUERIES.PROJ_USER_MAPPING,
        { orgId, projectId: selectedProjectId },
      ],
      AuthAPI.getProjectByID,
      { ...projectOptions, initialData: res }
    );
  }
  return { selectedProjectId, mappingDocPromise };
}

export function getCachedProjectRolesAndUserMapping(
  orgId: string,
  projectId: string
) {
  const projectMapping = queryCacheV2X.getQueryData([
    CRITICAL_QUERIES.PROJ_USER_MAPPING,
    { orgId, projectId },
  ]) as UserProjectMapping;
  const defaultProjectRoles = getProjectRolesFromQuery();
  const customProjectRoles = getCustomProjectRolesFromQuery(orgId, projectId);
  return { projectMapping, defaultProjectRoles, customProjectRoles };
}

function fetchOrgRoles(options: QueryConfigV2<RoleCollectionDocument[]> = {}) {
  return queryCacheV2X.fetchQuery(
    [CRITICAL_QUERIES.DEFAULT_ORG_ROLES],
    AuthAPI.getOrganizationRoles,
    options
  );
}

function fetchCustomOrgRoles(
  orgId: string,
  options: QueryConfigV2<CustomRoleDocument[]> = {}
) {
  return queryCacheV2X.fetchQuery(
    [CRITICAL_QUERIES.CUSTOM_ORG_ROLES, { orgId }],
    AuthAPI.getOrgCustomRoles,
    options
  );
}

function fetchProjectRoles(
  options: QueryConfigV2<RoleCollectionDocument[]> = {}
) {
  return queryCacheV2X.fetchQuery(
    [CRITICAL_QUERIES.DEFAULT_PROJ_ROLES],
    AuthAPI.getProjectRoles,
    options
  );
}

function fetchCustomProjectRoles(
  orgId: string,
  projectId: string,
  options: QueryConfigV2<CustomRoleDocument[]> = {}
) {
  return queryCacheV2X.fetchQuery(
    [CRITICAL_QUERIES.CUSTOM_PROJ_ROLES, { orgId, projectId }],
    AuthAPI.getProjectCustomRoles,
    options
  );
}

export function getCustomOrgRolesFromQuery(orgId: string) {
  return queryCacheV2X.getQueryData([
    CRITICAL_QUERIES.CUSTOM_ORG_ROLES,
    { orgId },
  ]) as CustomRoleDocument[];
}

export function getCustomProjectRolesFromQuery(
  orgId: string,
  projectId: string
) {
  return queryCacheV2X.getQueryData([
    CRITICAL_QUERIES.CUSTOM_PROJ_ROLES,
    { orgId, projectId },
  ]) as CustomRoleDocument[];
}

function getOrgRolesFromQuery() {
  return queryCacheV2X.getQueryData([
    CRITICAL_QUERIES.DEFAULT_ORG_ROLES,
  ]) as RoleCollectionDocument[];
}

function getProjectRolesFromQuery() {
  return queryCacheV2X.getQueryData([
    CRITICAL_QUERIES.DEFAULT_PROJ_ROLES,
  ]) as RoleCollectionDocument[];
}

export async function fetchCurrentUser() {
  try {
    return await queryCacheV2X.fetchQuery(
      [CRITICAL_QUERIES.USER_DOC],
      AuthAPI.getUserDoc,
      {
        retry(count, error) {
          if (
            error instanceof PXAPIError &&
            error.code === AUTH_ERRORS.SIGNUP_DISABLED
          ) {
            return false;
          }
          return count < 3;
        },
      }
    );
  } catch (error) {
    if (
      error instanceof PXAPIError &&
      error.code === AUTH_ERRORS.SIGNUP_DISABLED
    ) {
      throw new Error(PXERR_REJECTED_SIGNUP_ATTEMPT);
    }
    throw new Error(PXERR_USER_INIT_FAILED);
  }
}
