import { usePageLoader } from "components/PageLoader/PageLoader";
import { QueryStatusV2, useTypedQueryV2 } from "queries/useTypedQuery";
import { useEffect } from "react";
import { BROWSER } from "services/browser_info";

/**
 * will show the page loader until complete
 */
export function useGetConnectivity() {
  const connectivityQuery = useTypedQueryV2(
    ["connectivity"],
    BROWSER.checkInternetConnectivity,
    {
      refetchInterval: false,
      retry: false,
    }
  );
  const showPageLoader = usePageLoader();
  useEffect(() => {
    showPageLoader(connectivityQuery.status === QueryStatusV2.Loading);
  }, [connectivityQuery.status, showPageLoader]);
  return connectivityQuery;
}
