const ENABLED = "ENABLED";
const DISABLED = "DISABLED";
type SWITCH_VAR = typeof ENABLED | typeof DISABLED | undefined;

declare global {
  namespace NodeJS {
    interface ProcessEnv {
      REACT_APP_SW: SWITCH_VAR;
      REACT_APP_WIP_FEATURES: SWITCH_VAR;
      LOG_ROUTE_CHANGES: SWITCH_VAR;
      REACT_APP_TAG_NAME?: string;
      REACT_APP_SHORT_SHA?: string;
      REACT_APP_TIMESTAMP?: string;
      REACT_APP_AUTH_ENV?: "FIREBASE" | "ON_PREM";
    }
  }
}

/**
 * Environment variables provided during build
 * * if tree shaking needed use the expression direction instead of importing from here
 */
export const ENV = {
  get serviceWorkerEnabledInEnv() {
    return process.env.REACT_APP_SW === ENABLED;
  },
  get wipFeaturesEnabled() {
    if (process.env.NODE_ENV === "development") return true;
    return process.env.REACT_APP_WIP_FEATURES === ENABLED;
  },
  get isOnPrem() {
    return process.env.REACT_APP_AUTH_ENV === "ON_PREM";
  },
  get isFixedOrganization() {
    return process.env.REACT_APP_AUTH_ENV === "ON_PREM";
  },
  get billingDisabled() {
    return process.env.REACT_APP_AUTH_ENV === "ON_PREM";
  },
  get platformerServicesAvailable() {
    return process.env.REACT_APP_AUTH_ENV !== "ON_PREM";
  },
  signUpAllowed: false,
};
Object.freeze(ENV);

window.X_ENV = ENV;
