import { GAType, GoogleAnalytics } from "./ga";
import { GoogleAnalyticsOnPrem } from "./ga_on_prem";

let GA: GAType;
if (process.env.REACT_APP_AUTH_ENV === "ON_PREM") {
  GA = GoogleAnalyticsOnPrem;
} else {
  GA = GoogleAnalytics;
}

export { GA };
