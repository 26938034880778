import { APP_VERSION } from "services/app_version";
import { useAppVersionState, wbRegistration } from "./wb";

export function useVersionUpdateStatus() {
  const { hasUpdate, isActivating, resetUpdateState } = useAppVersionState(
    (s) => s
  );
  function applyUpdate() {
    if (!wbRegistration || !hasUpdate) return;
    resetUpdateState();
    // let waiting Service Worker know it should became active
    wbRegistration.skipWaiting();
  }
  return { hasUpdate, applyUpdate, isActivating };
}

(function logVersion() {
  const { TAG, SHA } = APP_VERSION;
  const version = TAG || SHA || "NA";
  const HEADER_STYLE = "font-size: 24px; font-family: sans-serif;";
  const MESSAGE_STYLE = "font-size: 14px; font-family: sans-serif;";
  const CODE_STYLE = "font-size: 14px; font-family: monospace;";
  console.info(
    `%cPlatformer Console
%c${version.startsWith("v") ? "" : "version: "}%c${version}
  `,
    HEADER_STYLE,
    MESSAGE_STYLE,
    CODE_STYLE
  );
})();
