import type firebase from "firebase/app";

export type FbUser = Pick<
  firebase.User,
  "displayName" | "email" | "uid" | "getIdToken"
>;

export function isFirebaseUser(v: any): v is FbUser {
  if (v === null || typeof v !== "object") return false;
  return typeof v.getIdToken === "function";
}
