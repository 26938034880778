import { authMachineService } from "services/auth/authMachine/authMachine";
import { userStore } from "store/userStore";
import { BROWSER } from "./../browser_info";

export const TAB_ID = Date.now().toString(36);
const KEY = "lastTabEvent";

type LOGGED_IN_MSG = {
  event: "LOGGED_IN_MSG";
  tabId: string;
  uidSubStr: string;
};

type LOGGED_OUT_MSG = {
  event: "LOGGED_OUT_MSG";
  tabId: string;
  uidSubStr: string;
};

type RELOAD_MSG = {
  event: "RELOAD_MSG";
  tabId: string;
  uidSubStr: string;
};

type TabEvent = LOGGED_IN_MSG | LOGGED_OUT_MSG | RELOAD_MSG;

export function init() {
  sessionStorage.setItem("TAB_ID", TAB_ID);
  susbscribeToTabEvents();
}

export function notifyOtherTabs(event: TabEvent["event"]) {
  const uidSubStr = userStore.getState().userId?.substring(0, 6) || "NO_USER";
  const ev: TabEvent = { event, tabId: TAB_ID, uidSubStr };
  localStorage.setItem(KEY, JSON.stringify(ev));
}

export function susbscribeToTabEvents() {
  window.addEventListener("storage", function onStorageEvent(ev) {
    if (ev.key !== KEY || !ev.newValue) return;
    const eventData = JSON.parse(ev.newValue) as TabEvent;
    if (eventData.tabId === TAB_ID) return;
    if (eventData.event === "LOGGED_IN_MSG") {
      authMachineService.send("LOGIN_TAB_SYNC");
    }
    if (eventData.event === "LOGGED_OUT_MSG") {
      authMachineService.send("LOGOUT_TAB_SYNC");
    }
    if (eventData.event === "RELOAD_MSG") {
      BROWSER.reload();
    }
  });
}
