import { AuthIdleActions } from "services/auth/auth_actions_interfaces";
import { firebaseAuthIdleActions } from "services/firebase/fb_auth_idle_actions";
import { OnPremiseAuth } from "services/onpremise/on_premise_auth";

let authIdleActions: AuthIdleActions;

if (process.env.REACT_APP_AUTH_ENV === "ON_PREM") {
  authIdleActions = OnPremiseAuth;
} else {
  authIdleActions = firebaseAuthIdleActions;
}
export { authIdleActions };
