export type UserId = string;
export type OrgId = string;
export type ProjectId = string;

export interface UserDocument {
  created_date: string;
  last_login_time: string | null;
  email: string;
  id: string;
  metadata: null;
  photo_url: string;
  roles: string[];
  type: string;
  username: string | null;
}

export interface OrgDocument {
  id: string;
  name: string;
  address: string;
  contacts: Array<{
    area_code: string;
    number: string;
    country: string;
  }>;
  domain: string;
  country: string;
  support_email: string;
  created_date: string;
  active: boolean;
  txt_code: string | null;
}

export interface ProjectDocument {
  created_by: string;
  created_date: string;
  description: string;
  id: string;
  name: string;
  organization_id: string;
  owner_id: string;
  billing_account_id: string;
  api_requests: Array<{
    type: string;
    status: boolean;
    request: any;
    response: any;
  }>;
}

export interface UserOrgMapping {
  created_date: string;
  id: string;
  name: string;
  organization_id: string;
  owner: boolean;
  pending: boolean;
  roles: Array<{ collection_id: string; roles_id: Array<string> }>;
  custom_roles: string[];
  uid: string;
  user_email: string;
  user_name: string | null;
  org_active?: boolean;
}

export interface UserProjectMapping extends UserOrgMapping {
  project_id: string;
  default_roles: Array<{ collection_id: string; roles_id: Array<string> }>;
}

interface Role {
  active: boolean;
  description: string;
  id: string;
  role_name: string;
}
export interface RoleCollectionDocument {
  created_by: string;
  created_date: string;
  id: string;
  name: string;
  organization_level: boolean;
  project_level: boolean;
  roles: Array<Role>;
  updated_by: string | null;
  updated_date: string | null;
}

export interface CustomRoleDocument {
  id: string;
  name: string;
  roles: Array<{
    collection_name: string;
    collection_id: string;
    roles: Array<Role>;
  }>;
}

export interface StripePlanDocument {
  amount: number;
  created_by: string;
  created_date: string;
  currancy: "usd";
  description: string;
  id: string;
  interval: "month";
  name: string;
  plan_id: string;
}

export interface BillingAccountDocument {
  id: string;
  name: string;
  organization_id: string;
  payment_method: BillingPlanTypes;
  projects: Array<string>;
  metadata: {
    stripe_card: any;
    invoice: any;
  };
  active: boolean;
  created_by: string;
  created_date: any;
  stripe_card: IStripeCard | null;
  invoice: IInvoice;
}

export interface IStripeCard {
  customer_id: string;
  source_id: string;
  owner: string;
  last4: string;
  type: cardTypes;
}

interface IInvoice {
  xero_user: {
    name: string;
    contact_id: any;
    email: string;
    phone_number: string;
    phone_area_code: string;
  };
  contact_email: string;
  contact_name: string;
}

enum BillingPlanTypes {
  Card = "card",
  Invoice = "invoice",
}

export enum cardTypes {
  AmericanExpress = "American Express",
  DinersClub = "Diners Club",
  Discover = "Discover",
  JCB = "JCB",
  MasterCard = "MasterCard",
  UnionPay = "UnionPay",
  Visa = "Visa",
  Unknown = "Unknown",
}

export interface InvoiceSubscription {
  id: string;
  account_id: string;
  plan_id: string;
  metadata?: any;
  support_user: {
    email: string;
    name: string;
  } | null;
}

export interface CardSubscription {
  id: string;
  account_id: string;
  plan_id: string;
  subscription_id: string;
  metadata: {
    subscription: any;
  };
  support_user: {
    email: string;
    name: string;
  } | null;
}

export interface Environment {
  ID: string;
  CreatedAt: string;
  UpdatedAt: string;
  DeletedAt: string | null;
  metadata: Record<string, string>;
  name: string;
  organization_id: string;
  project_id: string;
  description: string;
  namespace: string;
  namespaces: null;
  environment_clusters: null;
  critical: boolean;
  app_selector: Record<string, string> | null;
}

export interface EnvironmentDetails
  extends Omit<Environment, "environment_clusters" | "namespaces"> {
  environment_clusters: EnvironmentCluster[];
  namespaces: Array<EnvNamespace>;
}

export interface EnvNamespace {
  ID: string;
  metadata: null;
  CreatedAt: string;
  UpdatedAt: string;
  organization_id: string;
  project_id: string;
  name: string;
  environment_id: string;
  environment: null;
  annotations: null;
}

export interface EnvironmentCluster {
  ID: string;
  CreatedAt: string;
  UpdatedAt: string;
  DeletedAt: string | null;
  metadata: {
    system_namespace_create: true;
  };
  cluster: null;
  cluster_id: string;
  environment_id: string;
  environment: null;
  namespace: string;
  mode: "PRIMARY";
  config_override: null;
  secret_override: null;
}
export interface UserEnvMapping {
  created_date: string;
  id: string;
  env_id: string;
  name: string;
  organization_id: string;
  owner: boolean;
  pending: boolean;
  roles: Array<{ collection_id: string; roles_id: Array<string> }>;
  default_roles: Array<{ collection_id: string; roles_id: Array<string> }>;
  custom_roles: string[];
  uid: string;
  user_email: string;
  user_name: string;
}
