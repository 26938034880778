/**
 * * handle promise rejections
 * * delete prompt cancellation
 * * avoid `Unexpected empty arrow function` from sonarqube
 * * `FormContainer` with no submit action
 */
export function noop() {
  // does nothing
}

export async function asyncNoop() {
  // does nothing
}
