import { AUTH_ERRORS } from "api/auth";
import { PXAPIError } from "api/responseInterceptor";
import { FormError } from "components/FormFields/FormContainer/FormContainer";

export const ERROR_PERMISSION_DENIED =
  "You do not have the right privileges to perform this task, please speak to your Platform Console administrator";

/**
 * @param error
 * @throws `FormError`
 */
export function checkErrorForPermissions(error: any) {
  if (isPermissionError(error)) {
    throw new FormError("INSUFFICIENT_PRIVILEGES", ERROR_PERMISSION_DENIED);
  }
}

export function isPermissionError(error: any) {
  if (!(error instanceof PXAPIError)) return false;
  if (error.code === AUTH_ERRORS.UNAUTHORIZED) {
    return true;
  }
  return false;
}
