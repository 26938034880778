/** Intercom script */
export function loadIntercom(appId: string) {
  (window as any).intercomSettings = {
    app_id: appId,
    alignment: "right",
    horizontal_padding: 50,
    vertical_padding: 50,
  };
  var w: any = window;
  var ic = w.Intercom;
  if (typeof ic === "function") {
    ic("reattach_activator");
    ic("update", w.intercomSettings);
  } else {
    var d = document;
    var i: any = function () {
      i.c(arguments);
    };
    /**
     * collect function calls to window.Intercom,
     * to be used later when actual library is loaded
     * */
    i.q = [];
    i.c = function (args: any) {
      i.q.push(args);
    };
    w.Intercom = i;
    var l = function () {
      var s = d.createElement("script");
      s.type = "text/javascript";
      s.async = true;
      // eslint-disable-next-line no-useless-concat
      s.src = "https://widget.intercom.io/widget/" + appId;
      var x: any = d.getElementsByTagName("script")[0];
      x.parentNode.insertBefore(s, x);
    };
    /** load on module load, instead of waiting for load event */
    setTimeout(l, 8000);
    // if (w.attachEvent) {
    //   w.attachEvent("onload", l);
    // } else {
    //   w.addEventListener("load", l, false);
    // }
  }
}
