import { Portal } from "@material-ui/core";
import React, { useEffect, useReducer } from "react";
import { ConfigService } from "services/config";

const ReactQueryDevtools = React.lazy(() =>
  import("react-query/devtools/development").then((m) => {
    return { default: m["ReactQueryDevtools"] };
  })
);
export default function DevTools() {
  const [isEnabled, enableDevTools] = useReducer(
    () => true,
    process.env.NODE_ENV === "development"
  );
  useEffect(() => {
    ConfigService.waitTillInit().then((configLoaded) => {
      if (configLoaded && ConfigService.CONFIG?.RQ_DEV_TOOLS === "ENABLED") {
        enableDevTools();
      }
    });
  });
  if (!isEnabled) return null;
  return (
    <Portal>
      <ReactQueryDevtools />
    </Portal>
  );
}
