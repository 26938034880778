import firebase from "firebase/app";
import { authMachineService } from "services/auth/authMachine/authMachine";
import { AuthenticatedActions } from "services/auth/auth_actions_interfaces";
import { FbUser } from "services/firebase/FbUser";

export const firebaseAuthenticatedActions = /* #__PURE__ */ new (class
  implements AuthenticatedActions<FbUser> {
  getCurrentUser = () => {
    return firebase.auth().currentUser;
  };
  signOut = () => {
    authMachineService.send("LOGOUT");
  };
  clearAuthState = () => {
    return firebase.auth().signOut();
  };
  getPhotoURL = () => {
    return this.getCurrentUser()?.photoURL ?? null;
  };
  getToken = () => {
    return this.getCurrentUser()?.getIdToken() ?? null;
  };
})();
