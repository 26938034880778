import { browserHistory } from "navigation/routerHistory";
import { ConfigService } from "services/config";
import { OrgId, ProjectId } from "shared/interfaces/document_interfaces";
import { ServiceConfig } from "xstate";
import { LOGIN_PATH, SIGNUP_PATH } from "../auth_routes";
import { MachineContext } from "./interfaces";

export const routeChangeListener: ServiceConfig<MachineContext> = (ctx, e) => (
  callback,
  onReceive
) => {
  const unsubscribe = browserHistory.listen((location) => {
    const searchParams = new URLSearchParams(location.search);
    const orgId =
      ConfigService.CONFIG.FIXED_ORG_ID ?? (searchParams.get("org_id") || null);
    const projectId = orgId ? searchParams.get("proj_id") || null : null;
    callback({ type: "ROUTE_CHANGE", orgId, projectId });
  });
  return unsubscribe;
};

export function switchOrgProjectRoute(
  orgId: OrgId | null,
  projectId: ProjectId | null
) {
  const loc = browserHistory.location;
  // redirect to dashboard if on login/signup routes
  const isOnAuthRoute = [LOGIN_PATH, SIGNUP_PATH].includes(loc.pathname);
  const searchParams = new URLSearchParams(loc.search);
  if (ConfigService.CONFIG.FIXED_ORG_ID) {
    searchParams.delete("org_id");
    if (projectId) {
      searchParams.set("proj_id", projectId);
    } else {
      searchParams.delete("proj_id");
    }
  } else if (orgId) {
    searchParams.set("org_id", orgId);
    if (projectId) {
      searchParams.set("proj_id", projectId);
    } else {
      searchParams.delete("proj_id");
    }
  } else {
    searchParams.delete("org_id");
  }
  browserHistory.replace({
    pathname: isOnAuthRoute ? "/" : loc.pathname,
    search: searchParams.toString(),
    state: loc.state,
  });
}
