import React, { Component } from "react";
import { SentryService } from "services/sentry";
import { DefaultFallbackUI, FallbackUIProps } from "./DefaultFallbackUI";

export function checkIsChunkLoadError(error: Error | undefined) {
  try {
    return (
      (error?.name === "ChunkLoadError" ||
        error?.name === "SyntaxError " ||
        error?.message.startsWith("Loading CSS chunk") ||
        error?.message.startsWith("Loading chunk")) ??
      false
    );
  } catch (error) {
    return false;
  }
}

interface IProps {
  fallbackUI: JSX.Element | React.FC<FallbackUIProps> | null;
}

interface IState {
  eventId: string | null;
  hasError: boolean;
  error: Error | null;
}

export class ErrorBoundary extends Component<IProps, IState> {
  static defaultProps = {
    fallbackUI: DefaultFallbackUI,
  };
  constructor(props: IProps) {
    super(props);
    this.state = { eventId: null, hasError: false, error: null };
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, error };
  }

  componentDidCatch(error: any, errorStack: any) {
    const isChunkLoadError = checkIsChunkLoadError(error);
    SentryService.reportWithScope(
      error,
      { errorStack, isChunkLoadError },
      (eventId) => this.setState({ eventId })
    );
  }

  reportError = () => {
    const eventId = this.state.eventId as any;
    SentryService.showReportDialog({ eventId });
  };

  render() {
    if (!this.state.error) {
      //when there's not an error, render children untouched
      return this.props.children;
    }
    const FallbackUI = this.props.fallbackUI;
    //render fallback UI
    if (typeof FallbackUI === "function") {
      return (
        <FallbackUI sendFeedback={this.reportError} error={this.state.error} />
      );
    }
    return FallbackUI;
  }
}
