import { AxiosRequestConfig } from "axios";

export const axiosBaseConfig: AxiosRequestConfig = {
  baseURL: "INVALID_URL",
};

/**
 * allow 5min timeout for requests that fetch from clusters
 */
export const CLUSTER_REQUEST_TIMEOUT = 300000;
