const SERVICE_WORKER_SUPPORTED = "serviceWorker" in navigator;

export const FEATURES = {
  SERVICE_WORKER_SUPPORTED,
  get SERVICE_WORKER_STATE() {
    if (!SERVICE_WORKER_SUPPORTED) return "not_supported";
    const sw = navigator?.serviceWorker?.controller;
    if (!sw) return "not_found";
    return sw.state;
  },
};
