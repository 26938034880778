import React, { useContext } from "react";

export function createCtx<T>(name: string, defaultValue?: T) {
  const emptyValueErr: any = Symbol("MissingProvider");
  const ctx = React.createContext<T>(defaultValue ?? emptyValueErr);
  ctx.displayName = name;
  const { Provider } = ctx;
  function useCtx() {
    const value = useContext(ctx);
    if (value === emptyValueErr) throw new Error("MissingProvider");
    return value;
  }
  return [Provider, useCtx] as [React.Provider<T>, () => T];
}
