import { PXAPIError } from "api/responseInterceptor";
import { authInstance } from "./authInstance";
import {
  OrgServiceAccountAddData,
  OrgServiceAccountEditData,
  OrgServiceAccountResposeData,
  ProjectServiceAccountAddData,
  ProjectServiceAccountEditData,
  ProjectServiceAccountResposeData,
} from "./interfaces";

//Service Accounts
///Organization
export async function getOrgServiceAccounts(
  key: string,
  { orgId, projectId }: { orgId: string; projectId: string }
) {
  const PATH = `/serviceaccount/organization/${orgId}`;
  try {
    const res = await authInstance.get(PATH);
    const apiRes = res.data;
    return apiRes.data as OrgServiceAccountResposeData[];
  } catch (e) {
    if (e instanceof PXAPIError) throw e;
    throw new PXAPIError("Error occured", "ERROR", { error: e });
  }
}

export async function addOrgServiceAccount(data: OrgServiceAccountAddData) {
  const PATH = "/serviceaccount/organization/create";
  try {
    const res = await authInstance.post(PATH, data, {});
    return res.data;
  } catch (e) {
    if (e instanceof PXAPIError) throw e;
    throw new PXAPIError("Error occured", "ERROR", { error: e });
  }
}
export async function updateOrgServiceAccount(data: OrgServiceAccountEditData) {
  const PATH = "/serviceaccount/update";
  try {
    const res = await authInstance.put(PATH, data, {});
    return res.data;
  } catch (e) {
    if (e instanceof PXAPIError) throw e;
    throw new PXAPIError("Error occured", "ERROR", { error: e });
  }
}
export async function generateServiceAccountKey(data: {
  account_id: string;
  expired_in: string | null;
}) {
  const PATH = "/serviceaccount/key/generate";
  try {
    const res = await authInstance.post(PATH, data, {});
    return res.data;
  } catch (e) {
    if (e instanceof PXAPIError) throw e;
    throw new PXAPIError("Error occured", "ERROR", { error: e });
  }
}
export async function removeServiceAccount({
  accountId,
}: {
  accountId: string;
}) {
  const PATH = `/serviceaccount/${accountId}`;
  try {
    const res = await authInstance.delete(PATH);
    return res.data;
  } catch (e) {
    if (e instanceof PXAPIError) throw e;
    throw new PXAPIError("Error occured", "ERROR", { error: e });
  }
}
///project

export async function getProjectServiceAccounts(
  key: string,
  { orgId, projectId }: { orgId: string; projectId: string }
) {
  const PATH = `/serviceaccount/project/${orgId}/${projectId}`;
  try {
    const res = await authInstance.get(PATH);
    const apiRes = res.data;
    return apiRes.data as ProjectServiceAccountResposeData[];
  } catch (e) {
    if (e instanceof PXAPIError) throw e;
    throw new PXAPIError("Error occured", "ERROR", { error: e });
  }
}

export async function addProjectServiceAccount(
  data: ProjectServiceAccountAddData
) {
  const PATH = "/serviceaccount/project/create";
  try {
    const res = await authInstance.post(PATH, data, {});
    return res.data;
  } catch (e) {
    if (e instanceof PXAPIError) throw e;
    throw new PXAPIError("Error occured", "ERROR", { error: e });
  }
}
export async function updateProjectServiceAccount(
  data: ProjectServiceAccountEditData
) {
  const PATH = "/serviceaccount/update";
  try {
    const res = await authInstance.put(PATH, data, {});
    return res.data;
  } catch (e) {
    if (e instanceof PXAPIError) throw e;
    throw new PXAPIError("Error occured", "ERROR", { error: e });
  }
}
