import RefreshIcon from "@material-ui/icons/Refresh";
import UpdateIcon from "@material-ui/icons/Update";
import AlertImageCard from "components/AlertImageCard/AlertImageCard";
import { PageLoader } from "components/PageLoader/PageLoader";
import { checkIsChunkLoadError } from "containers/ErrorBoundary/ErrorBoundary";
import { useGetConnectivity } from "containers/ErrorHandlers/useGetConnectivity";
import { useVersionUpdateStatus } from "modules/VersionUpdater/useVersionUpdateStatus";
import { SW } from "modules/VersionUpdater/wb";
import React from "react";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";
import { BROWSER } from "services/browser_info";
import { SentryService } from "services/sentry";
import FailureContainer from "./FailureContainer";

/**
 * Handling cache cleared errors on service workers
 * * if main chunk fails (unlikely), need a way to unregister silently & reload.
 * * handled by `failed_sw.js`
 * other chunk load errors will be handled by `GlobalErrorBoundary`
 *
 */
/**
 * Handles chunk load errors, and any other render errors that propagate to react root
 * ( shouldn't reach here on n/w errors)
 * * on ChunkLoadError
 * * * If service worker is enabled
 * * * * It might be missing files in cache with either a network failure,
 * a file being no longer available on server.
 * * on Render Errors
 * * * Display a generic error message, & sentry fallback
 */
export function GlobalErrorBoundary({ children }: React.PropsWithChildren<{}>) {
  return (
    <ErrorBoundary
      FallbackComponent={GlobalErrorFallback}
      onError={(err, errorStack) =>
        SentryService.reportWithScope(err, {
          ...errorStack,
          isChunkLoadError: checkIsChunkLoadError(err),
          global: true,
        })
      }
    >
      {children}
    </ErrorBoundary>
  );
}

function GlobalErrorFallback({ error }: FallbackProps) {
  useGetConnectivity();
  const { hasUpdate, applyUpdate, isActivating } = useVersionUpdateStatus();
  const isChunkLoadError = checkIsChunkLoadError(error);
  if (isActivating) return <PageLoader />;
  const CHUNKERR_HASUPDATE =
    "Failed to load application. This is likely due to browser cache clearance. Try updating to the latest version.";
  if (isChunkLoadError && hasUpdate) {
    return (
      <FailureContainer>
        <AlertImageCard
          cardImgClass="new-version"
          severity="info"
          btnIcon={<UpdateIcon />}
          secondaryMessage=""
          message={CHUNKERR_HASUPDATE}
          onBtnClick={applyUpdate}
          btnText="Update"
        />
      </FailureContainer>
    );
  }
  const swEnabled = BROWSER.serviceWorkerEnabled;
  const CHUNKERR_NOUPDATE =
    "Failed to load application. This is likely due to browser cache clearance. Reloading the page might solve the issue.";
  if (isChunkLoadError && swEnabled) {
    return (
      <FailureContainer>
        <AlertImageCard
          btnIcon={<RefreshIcon />}
          cardImgClass="complex-error"
          severity="info"
          secondaryMessage=""
          message={CHUNKERR_NOUPDATE}
          btnText="Reload"
          onBtnClick={SW.cleanUpServiceWorkerAndReload}
        />
      </FailureContainer>
    );
  }
  if (isChunkLoadError) {
    return (
      <FailureContainer>
        <AlertImageCard
          btnIcon={<RefreshIcon />}
          cardImgClass="complex-error"
          severity="info"
          secondaryMessage=""
          message={CHUNKERR_NOUPDATE}
          btnText="Reload"
          onBtnClick={BROWSER.clearAllBrowserCachesNReload}
        />
      </FailureContainer>
    );
  }
  const UNKOWN_ERR =
    "Failed to load application. Reloading the page might solve the issue.";
  return (
    <FailureContainer>
      <AlertImageCard
        btnIcon={<RefreshIcon />}
        cardImgClass="complex-error"
        severity="info"
        secondaryMessage=""
        message={UNKOWN_ERR}
        btnText="Reload"
        onBtnClick={BROWSER.reload}
      />
    </FailureContainer>
  );
}
