import { AuthModuleAPI } from "api/auth/auth_apis";
import { OnPremUser, signInOnPrem } from "api/auth/auth_endpoints";
import { authMachineService } from "services/auth/authMachine/authMachine";
import {
  AuthenticatedActions,
  AuthIdleActions,
  AuthMainActions,
} from "services/auth/auth_actions_interfaces";
import { waitFor } from "utls/async_utils";
import { tokenEventsEmitter, Tokens, tokenService } from "./tokenService";

const USER_DATA = "px_user";

export const OnPremiseAuth = /* #__PURE__ */ new (class
  implements
    AuthIdleActions,
    AuthMainActions<OnPremUser>,
    AuthenticatedActions<OnPremUser> {
  private user: OnPremUser | null = null;
  init = () => {
    try {
      const hasTokens = tokenService.initialize();
      const usr_str = localStorage.getItem(USER_DATA);
      if (!hasTokens || !usr_str) return this.clear();
      const userObj = JSON.parse(usr_str);
      if (!userObj) return this.clear();
      this.user = userObj;
    } catch (error) {
      return this.clear();
    }
  };
  clear = () => {
    localStorage.removeItem(USER_DATA);
    tokenService.stop();
  };
  getPhotoURL = () => {
    return this.user?.imageUrl ?? null;
  };
  signOut = () => {
    authMachineService.send("LOGOUT");
  };
  clearAuthState = async () => {
    this.clear();
  };
  signIn = async (info: AuthModuleAPI.SignInDetails) => {
    const { email, password } = info;
    const res = await signInOnPrem({ email, password });
    if (!res.success) {
      throw new Error(res.errorCode);
    }
    const newTokens: Tokens = {
      token: res.token,
      refreshToken: res.refreshToken,
      updatedAt: Date.now(),
    };
    tokenService.start(newTokens);
    this.user = Object.freeze(res.user);
    localStorage.setItem(USER_DATA, JSON.stringify(res.user));
    authMachineService.send({
      type: "LOGIN_WITH_EMAIL_SUCCESS",
      data: res.user,
    });
  };
  getToken = async () => {
    const token = tokenService.getToken();
    if (token) return token;
    // token expired
    const waitForNewToken = new Promise<string>((resolve) => {
      const { dispose } = tokenEventsEmitter.on(function onUpdate(ev) {
        if (ev.type === "TOKEN_UPDATED") {
          resolve(ev.tokens.token);
          dispose();
        }
      });
    });
    const timeOut = waitFor(8000).then(() => null);
    return Promise.race([waitForNewToken, timeOut]);
  };
  getCurrentUser = (): Readonly<AuthModuleAPI.OnPremUser> | null => {
    return this.user;
  };
  getLoggedInUserOnPageLoad = async () => {
    return this.getCurrentUser();
  };
})();
