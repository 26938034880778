import { addAuthInterceptor } from "api/authInterceptor";
import { addResponseInterceptor } from "api/responseInterceptor";
import axios from "axios";
import { ConfigService } from "services/config";
import { axiosBaseConfig } from "./../axiosBaseConfig";

const authInstance = axios.create(axiosBaseConfig);

addAuthInterceptor(authInstance);
addResponseInterceptor(authInstance);

authInstance.interceptors.request.use(
  async function (config) {
    config.baseURL = ConfigService.API.AUTH;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export { authInstance };
