import {
  PXERR_CONFIG_NOT_AVAILABLE,
  PXERR_FIXED_ORG_ID_REQUIRED,
} from "./../app_errors";
import { ENV } from "./../env_variables";

/**
 * API names
 */
const APIS = [
  "AUTH",
  "BILLING",
  "AUDIT",
  "MIZZEN",
  "MIZZEN_SOCKET",
  "RUDDER",
  "VAULT",
  "CICD",
  "HELM",
  "OCEAN",
] as const;

/**
 * Other configs
 */
const CONFIGS = [
  "FIREBASE",
  "STRIPE",
  "SENTRY_DSN",
  "SENTRY_SAMPLE_RATE",
  "SENTRY_IGNORE_ERRORS",
  "INTERCOM_WORKSPACE",
  "FIREBASE_EVENTS",
  "INTERCOM_APP_ID",
  "RQ_DEV_TOOLS",
  "GITHUB",
  "USE_SW",
  "USE_PRECACHE",
  "FIXED_ORG_ID",
  "TC_VERSION",
] as const;

/**
 * Define non string config types here,
 * should be added to CONFIGS array too
 */
interface ConfigObjects {
  FIREBASE: Record<string, string>;
  GITHUB: {
    CLIENT_ID: string;
    REDIRECT_URI: string;
  };
  STRIPE: {
    KEY: string;
  };
  SENTRY_SAMPLE_RATE: number;
  SENTRY_IGNORE_ERRORS?: Array<string>;
  FIREBASE_EVENTS?: "ENABLED" | "DISABLED";
  RQ_DEV_TOOLS?: "ENABLED" | "DISABLED";
  USE_SW?: "ENABLED" | "DISABLED";
  USE_PRECACHE?: "ENABLED" | "DISABLED";
  FIXED_ORG_ID?: string;
}

/**
 * Derived interfaces
 */
type ConfigNames = typeof CONFIGS[number];
type StringConfigs = Exclude<ConfigNames, keyof ConfigObjects>;

type URL_MAP = Record<typeof APIS[number], string>;
type CONFIG_MAP = Pick<
  Record<StringConfigs, string> & ConfigObjects,
  ConfigNames
>;

const context: {
  API: URL_MAP | null;
  CONFIG: CONFIG_MAP | null;
  loaded: boolean;
  onLoad(): void;
  onError(): void;
} = {
  API: null,
  CONFIG: null,
  loaded: false,
  onLoad() {
    /** resolve configLoadPromise on load */
  },
  onError() {
    /** resolve configLoadPromise on error */
  },
};

const configLoadPromise = new Promise((resolve) => {
  context.onLoad = () => resolve(true);
  context.onError = () => resolve(false);
});

export const ConfigService = {
  get CONFIG() {
    return context.CONFIG as CONFIG_MAP;
  },
  get API() {
    return context.API as URL_MAP;
  },
  init() {
    if (context.loaded) {
      throw new Error("Config service already initialized.");
    }
    try {
      const { urls, configs } = loadConfig();
      context.loaded = true;
      context.API = urls;
      context.CONFIG = configs;
      context.onLoad();
    } catch (e) {
      context.onError();
      throw e;
    }
  },
  /**
   * if config.js failed to load, cannot continue to init sentry/intercom etc.
   */
  get initialized() {
    return context.loaded;
  },
  waitTillInit() {
    return configLoadPromise;
  },
};

function loadConfig() {
  if (!window.X_CONFIG_LOADED) {
    throw new Error(PXERR_CONFIG_NOT_AVAILABLE);
  }
  const urls: any = {};
  for (const moduleName of APIS) {
    urls[moduleName] = (window as any).API_BASE_URLS[moduleName];
  }
  const configs: any = {};
  for (const service of CONFIGS) {
    configs[service] = (window as any).CONFIGS[service];
  }
  if (ENV.isFixedOrganization) {
    const FIXED_ORG_ID = localStorage.getItem("FIXED_ORG_ID");
    if (FIXED_ORG_ID) {
      configs["FIXED_ORG_ID"] = FIXED_ORG_ID;
    }
    if (!configs["FIXED_ORG_ID"]) {
      throw new Error(PXERR_FIXED_ORG_ID_REQUIRED);
    }
  } else {
    delete configs["FIXED_ORG_ID"];
  }
  return { urls, configs };
}

declare global {
  interface Window {
    X_CONFIG_LOADED: boolean;
    X_APP_LOADED: boolean;
    X_ENV: typeof ENV;
  }
}
