import { AxiosInstance, AxiosRequestConfig } from "axios";
import { authenticatedActions } from "services/auth/authenticated_actions";
import { orgStore } from "store/orgStore";
import { projectStore } from "store/projectStore";
import { userStore } from "store/userStore";
import { HEADERS } from "./headers";
import { PXAPIError } from "./responseInterceptor";

interface RequestConfig extends AxiosRequestConfig {
  metadata?: {
    skipHeaderAdd?: boolean;
    skipToken?: boolean;
    startTime?: number;
  };
}
export function addAuthInterceptor(instance: AxiosInstance) {
  instance.interceptors.request.use(
    async function addHeaders(config: RequestConfig) {
      const { headers, metadata } = config;
      config.metadata = { ...metadata, startTime: Date.now() };
      if (config.metadata.skipHeaderAdd) {
        return config;
      }
      const authHeaders = await getAuthHeaders(
        config,
        config.metadata.skipToken
      );
      config.headers = { ...headers, ...authHeaders };
      return config;
    },
    function onError(error) {
      throw error;
    }
  );
}

const { ORG_ID, PROJECT_ID, USER_ID, ENV_ID } = HEADERS;
export async function getAuthHeaders(
  config?: RequestConfig,
  skipToken: boolean = false
) {
  const headers = config?.headers as Record<string, string> | undefined;
  const user = userStore.getState();
  const org = orgStore.getState();
  const project = projectStore.getState();
  const token = skipToken ? undefined : await authenticatedActions.getToken();
  if (!skipToken && !token) {
    throw new PXAPIError("Failed to get token", "TOKEN_GET_FAILURE", {
      sendReport: false,
      reqData: config,
    });
  }
  return {
    [USER_ID]: user.userId,
    [ORG_ID]: headers?.[ORG_ID] || org.orgId,
    [PROJECT_ID]: headers?.[PROJECT_ID] || project.projectId,
    [ENV_ID]: project.activeEnvId,
    Authorization: token,
  };
}
