import { AuthModuleAPI } from "api/auth/auth_apis";
import { getNewOnPremToken } from "api/auth/auth_endpoints";
import { waitFor } from "utls/async_utils";
import { TypedEvent } from "utls/event_emitter";

export interface Tokens {
  token: string;
  refreshToken: string;
  updatedAt: number;
}

interface TokenUpdateEvent {
  type: "TOKEN_UPDATED";
  tokens: Tokens;
}
interface TokenExpiredEvent {
  type: "TOKEN_EXPIRED";
}
export const tokenEventsEmitter = /* #__PURE__ */ new TypedEvent<
  TokenUpdateEvent | TokenExpiredEvent
>();

if (process.env.REACT_APP_AUTH_ENV === "ON_PREM") {
  (window as any)["tokenEventsEmitter"] = tokenEventsEmitter;
}

const TOKEN_KEY = "px_token";

function persistToken(tokens: Tokens) {
  const strVal = JSON.stringify(tokens);
  localStorage.setItem(TOKEN_KEY, strVal);
}
function stringToTokens(str: string | null): Tokens | null {
  if (str === null) return null;
  try {
    const parsed = JSON.parse(str);
    if (!parsed || typeof parsed !== "object") return null;
    const token = parsed["token"];
    const refreshToken = parsed["refreshToken"];
    const updatedAt = parsed["updatedAt"];
    if (
      typeof token === "string" &&
      typeof refreshToken === "string" &&
      typeof updatedAt === "number"
    ) {
      return { token, refreshToken, updatedAt };
    }
  } catch (error) {}
  return null;
}

export const tokenService = /* #__PURE__ */ new (class {
  private tokens: Tokens | null = null;
  private onGoingRequest: Promise<AuthModuleAPI.TokenRefreshSuccess> | null = null;
  private status: "expired" | "valid" | "waiting" = "waiting";
  private attemptRemaining = 3;
  private get enabled() {
    return !!this.tokens;
  }
  initialize = () => {
    this.tokens = stringToTokens(localStorage.getItem(TOKEN_KEY));
    if (this.tokens) {
      this.status =
        Date.now() > this.tokens.updatedAt + 50 * 60000 ? "expired" : "waiting";
      setTimeout(() => {
        if (!this.tokens) return;
        this.sendRequest();
      }, 0);
    }
    this.startListeners();
    return !!this.tokens;
  };
  startListeners = () => {
    tokenEventsEmitter.on((ev) => {
      if (ev.type === "TOKEN_EXPIRED") {
        if (this.tokens && Date.now() < this.tokens.updatedAt + 30000) {
          return; // ignore requests that were sent before the token refresh
        }
        this.status = "expired";
        this.attemptRemaining = 3;
        if (this.onGoingRequest) return;
        this.sendRequest();
      }
    });
    window.addEventListener("storage", (ev) => {
      if (ev.key !== TOKEN_KEY || !ev.newValue) return;
      const eventData = ev.newValue;
      const receivedTokens = stringToTokens(eventData);
      if (receivedTokens) {
        this.tokens = receivedTokens;
        this.status = "valid";
      }
    });
  };
  stop = () => {
    this.tokens = null;
    this.onGoingRequest = null;
    this.status = "waiting";
  };
  start = (newTokens: Tokens) => {
    this.tokens = newTokens;
    this.onGoingRequest = null;
    this.status = "valid";
    persistToken(newTokens);
  };
  getToken = () => {
    if (this.status !== "valid") return null;
    return this.tokens?.token ?? null;
  };
  private sendRequest = async () => {
    if (!this.tokens) return;
    if (this.attemptRemaining === 0) return;
    if (this.onGoingRequest) return;
    this.onGoingRequest = getNewOnPremToken({
      token: this.tokens.token,
      refreshToken: this.tokens.refreshToken,
    });
    try {
      const res = await this.onGoingRequest;
      this.onGoingRequest = null;
      if (!this.enabled) return; //stopped
      this.status = "valid";
      const newTokens: Tokens = {
        token: res.token,
        refreshToken: res.refreshToken || this.tokens.refreshToken,
        updatedAt: Date.now(),
      };
      this.tokens = newTokens;
      tokenEventsEmitter.emit({ type: "TOKEN_UPDATED", tokens: newTokens });
      persistToken(newTokens);
    } catch (error) {
      await waitFor(1000);
      this.attemptRemaining--;
      this.onGoingRequest = null;
      this.sendRequest();
    }
  };
})();
