import logoImg from "assets/img/logo/platformer-logo.png";
import React from "react";
import "./FailureContainer.scss";

interface FailureContainerProps {
  children: React.ReactNode;
}
const FailureContainer = ({ children }: FailureContainerProps) => {
  return (
    <div className="auth-fail-cont">
      <div className="fail-logo-wrap">
        <div className="logo-full">
          <img className="logo-full-img" src={logoImg} alt="Platformer Logo" />
        </div>
      </div>
      <div className="auth-fail-wrap">{children}</div>
    </div>
  );
};
export default FailureContainer;
