import { FetchQueryOptions, QueryClient } from "react-query";
import { isPermissionError } from "shared/errors/commonFormErrors";

/**
 * use `queryCacheV2X` for access queryClient
 */
export const queryClientV3X = new QueryClient({
  defaultOptions: {
    queries: {
      retry(count, err) {
        if (isPermissionError(err)) return false;
        return count < 3;
      },
      staleTime: 15 * 60 * 1000,
      cacheTime: 15 * 60 * 1000,
      refetchOnWindowFocus: true,
      refetchInterval: false,
      refetchOnMount: true,
    },
  },
});

type TypedQueryFunctionArgs = readonly [unknown, ...unknown[]];
type TypedQueryFunction<
  TResult,
  TArgs extends TypedQueryFunctionArgs = TypedQueryFunctionArgs
> = (...args: TArgs) => TResult | Promise<TResult>;

export const queryCacheV2X = {
  fetchQuery<TResult, TKey extends TypedQueryFunctionArgs>(
    queryKey: TKey,
    queryFn: TypedQueryFunction<TResult, TKey>,
    options?: FetchQueryOptions<TResult, unknown, TResult>
  ) {
    return queryClientV3X.fetchQuery<TResult, unknown, TResult>(
      queryKey,
      () => queryFn(...queryKey),
      options
    );
  },
  prefetchQuery<TResult, TKey extends TypedQueryFunctionArgs>(
    queryKey: TKey,
    queryFn: TypedQueryFunction<TResult, TKey>,
    options?: FetchQueryOptions
  ) {
    return queryClientV3X.prefetchQuery(
      queryKey,
      () => queryFn(...queryKey),
      options
    );
  },
  invalidateQueries(...args: Parameters<QueryClient["invalidateQueries"]>) {
    return queryClientV3X.invalidateQueries(...args);
  },
  refetchQueries(...args: Parameters<QueryClient["refetchQueries"]>) {
    return queryClientV3X.refetchQueries(...args);
  },
  removeQueries(...args: Parameters<QueryClient["removeQueries"]>) {
    return queryClientV3X.removeQueries(...args);
  },
  setQueryData(...args: Parameters<QueryClient["setQueryData"]>) {
    return queryClientV3X.setQueryData(...args);
  },
  getQueryData<T>(...args: Parameters<QueryClient["getQueryData"]>) {
    return queryClientV3X.getQueryData<T>(...args);
  },
};
