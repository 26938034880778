import { UserDocument, UserId } from "shared/interfaces/document_interfaces";
import createHook from "zustand";
import createStore from "zustand/vanilla";

type UserState = "init" | "loading" | "valid" | "invalid" | "error";

type IUserModel = {
  userId: UserId | null;
  validity: UserState;
  document: UserDocument | null;
  setUser(userId: UserId): void;
  updateDocument(doc: UserDocument): void;
  reset(): void;
  setValidity(v: UserState): void;
};

export const userStore = createStore<IUserModel>((set, get) => ({
  userId: null,
  document: null,
  validity: "init",
  setValidity(val) {
    if (val === "invalid") {
      set({ validity: val, document: null });
    } else {
      set({ validity: val });
    }
  },
  setUser(userId) {
    if (get().userId === userId) return;
    set({ userId, document: null, validity: "loading" });
  },
  updateDocument(payload) {
    set({ document: payload, validity: "valid" });
  },
  reset() {
    set({ userId: null, document: null, validity: "init" });
  },
}));

export const useUserStore = createHook(userStore);
