import { noop } from "utls/noop";
import type { GAType } from "./ga";

export const GoogleAnalyticsOnPrem: GAType = {
  onLoad: noop,
  stop: noop,
  enqueue: noop,
  onUserInit: noop,
  trackPage: noop,
  trackEvent: noop,
  trackClick(...args: any[]) {
    if (typeof args[1] === "function") {
      return args[1];
    }
  },
};
