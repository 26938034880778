import { ConfigService } from "services/config";
import { GA } from "services/firebase/ga";
import { orgStore } from "store//orgStore";
import { loadIntercom } from "./intercom";
import { IntercomEvents } from "./intercom_events";
// https://www.intercom.com/help/en/articles/170-integrate-intercom-in-a-single-page-app

const intercom = (...args: any[]) => (window as any).Intercom(...args);
export { IntercomEvents as Events };

const IsBetaHost = window.location.host === "beta.console.platformer.com";
const domainInfo = IsBetaHost
  ? {
      BetaUser: true,
    }
  : undefined;

function getAppContext() {
  const orgId = orgStore.getState().orgId || "NOT_SELECTED";
  return { orgId };
}

export function init() {
  if (!ConfigService.initialized) return;
  const WORKSPACE_ID = ConfigService.CONFIG.INTERCOM_APP_ID;
  loadIntercom(WORKSPACE_ID);
  intercom("boot", {
    app_id: WORKSPACE_ID,
    ...domainInfo,
  });
}

interface LoginUpdate {
  user_id: string;
  name: string;
  email?: string;
}

export function login(update: LoginUpdate) {
  const data = {
    app_id: ConfigService.CONFIG.INTERCOM_APP_ID,
    ...update,
    ...domainInfo,
  };
  intercom("boot", data);
}

interface KeyValueObj {
  [x: string]: string | number;
}

export function update(update: KeyValueObj = {}) {
  intercom("update", { ...update, ...domainInfo });
}

export function logout() {
  intercom("shutdown");
  init();
}

export function openWithMessage(msg: string) {
  intercom("showNewMessage", msg);
}

/**
 * tracks create events
 * @param eventName
 * @param metadata
 */
export function trackEvent(
  eventName: typeof IntercomEvents[keyof typeof IntercomEvents],
  metadata?: Record<string, any>
) {
  intercom("trackEvent", eventName, {
    ...metadata,
    ...domainInfo,
    ...getAppContext(),
    host: window.location.host,
  });
  GA.trackEvent(eventName, metadata);
}

export function trackGAEvent(eventName: string) {
  intercom("trackEvent", eventName, {
    ...domainInfo,
    ...getAppContext(),
    host: window.location.host,
  });
}

export function setLauncherVisible() {
  intercom("update", { hide_default_launcher: false });
}
export function setLauncherHidden() {
  intercom("update", { hide_default_launcher: true });
}
