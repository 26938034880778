import { browserHistory } from "navigation/routerHistory";
import { ConfigService } from "services/config";
import { ENV } from "services/env_variables";
import {
  OrgId,
  ProjectId,
  UserId,
} from "shared/interfaces/document_interfaces";
import {
  CLI_LOGIN_PATH,
  EMAIL_VERIFIED_PATH,
  LOGIN_PATH,
  SIGNUP_PATH,
} from "./auth_routes";

const INITIAL_URL = browserHistory.location;
export const INITIAL_PATH = INITIAL_URL.pathname + INITIAL_URL.search;
export const initialRouteSearchParams = new URLSearchParams(INITIAL_URL.search);

export function getInitialOrgProjectIds(
  userId: UserId
): Record<"orgId" | "projectId", string | null> {
  // uses `|| null` to remove empty string
  if (ENV.isFixedOrganization) {
    const orgId = ConfigService.CONFIG.FIXED_ORG_ID!;
    let projectId = initialRouteSearchParams.get("proj_id") || null;
    if (!projectId) {
      const uid = localStorage.getItem("uid");
      if (userId.substring(0, 6) === uid) {
        projectId = localStorage.getItem("proj_id") || null;
      }
    }
    return { orgId, projectId };
  }
  let orgId = initialRouteSearchParams.get("org_id") || null;
  let projectId = (orgId && initialRouteSearchParams.get("proj_id")) || null;
  if (orgId) {
    return { orgId, projectId };
  }
  const uid = localStorage.getItem("uid");
  if (userId.substring(0, 6) === uid) {
    orgId = localStorage.getItem("org_id") || null;
    projectId = (orgId && localStorage.getItem("proj_id")) || null;
  }
  return { orgId, projectId };
}

interface IPersistSelection {
  userId: UserId;
  orgId: OrgId | null;
  projectId: ProjectId | null;
}
export function persistSelection({
  userId,
  orgId,
  projectId,
}: IPersistSelection) {
  localStorage.setItem("uid", userId.substring(0, 6));
  if (orgId) localStorage.setItem("org_id", orgId);
  else localStorage.removeItem("org_id");
  if (projectId) localStorage.setItem("proj_id", projectId);
  else localStorage.removeItem("proj_id");
}

export function redirectToLoginRegister() {
  if (
    [LOGIN_PATH, SIGNUP_PATH, EMAIL_VERIFIED_PATH].includes(
      browserHistory.location.pathname
    )
  )
    return;
  browserHistory.replace("/login");
}
export function redirectToCliLoginPage() {
  browserHistory.replace(CLI_LOGIN_PATH);
}
