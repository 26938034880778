const TAG = process.env.REACT_APP_TAG_NAME;
const SHA = process.env.REACT_APP_SHORT_SHA;
const RELEASE = `${TAG}_${SHA}`;

export const APP_VERSION = { TAG, SHA, RELEASE };

export interface SW_RELEASE_INFO {
  TAG: string;
  SHA: string;
  BUILD_TIMESTAMP: string;
  RELEASE: string;
  SW_VERSION: string;
}
