import { OrgId, UserOrgMapping } from "shared/interfaces/document_interfaces";
import createHook from "zustand";
import createStore from "zustand/vanilla";

export type OrgState =
  | "init"
  | "loading"
  | "valid"
  | "invalid"
  | "error"
  | "empty";

type IOrgModel = {
  orgId: OrgId | null;
  validity: OrgState;
  document: UserOrgMapping | null;
  setOrganization(id: OrgId): void;
  setEmpty(): void;
  updateDocument(mapping: UserOrgMapping): void;
  setValidity(validity: OrgState): void;
  reset(): void;
  deleteStates: Record<string, "deleting" | "deleted" | null>;
  setDeleteState(orgId: string, state: "deleting" | "deleted" | "none"): void;
};

export const orgStore = createStore<IOrgModel>((set, get) => ({
  orgId: null,
  document: null,
  validity: "init",
  deleteStates: {},
  setOrganization(incomingOrgId) {
    if (get().orgId === incomingOrgId) return;
    set({
      orgId: incomingOrgId,
      validity: "loading",
      document: null,
    });
  },
  setEmpty() {
    set({ orgId: null, document: null, validity: "empty" });
  },
  updateDocument(payload) {
    set({ document: payload });
  },
  setValidity(validity) {
    set({ validity: validity });
  },
  reset() {
    set({ orgId: null, document: null, validity: "init" });
  },
  setDeleteState(orgId, state) {
    if (state === "none") {
      set({ deleteStates: { ...get().deleteStates, [orgId]: null } });
    } else {
      set({ deleteStates: { ...get().deleteStates, [orgId]: state } });
    }
  },
}));

export const useOrgStore = createHook(orgStore);
