import { Button, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import clsx from "clsx";
import React from "react";
import "./AlertImageCard.scss";

interface AlertImageCardProps {
  message?: string;
  cardImgClass?: string;
  severity: "success" | "info" | "warning" | "error" | undefined | "";
  secondaryMessage?: string;
  onBtnClick?: () => void;
  btnText?: string;
  btnIcon?: JSX.Element;
  actions?: JSX.Element;
  children?: JSX.Element;
  coverMoreHeight?: boolean;
}
const AlertImageCard = ({
  cardImgClass,
  message,
  severity,
  secondaryMessage,
  onBtnClick,
  btnText,
  btnIcon,
  actions,
  children,
  coverMoreHeight,
}: AlertImageCardProps) => {
  return (
    <>
      <div className="alert-image-card">
        {cardImgClass && cardImgClass !== "" && (
          <div className="alert-img">
            <div
              className={clsx(
                "cover-img",
                { "cover-more-height": coverMoreHeight },
                cardImgClass
              )}
            />
          </div>
        )}
        <div className="alert-msg">
          {message && (
            <div className={`alert-msg-txt ${severity}`}>{message}</div>
          )}
          {children && (
            <div className={`alert-msg-txt mt-3 ${severity}`}>{children}</div>
          )}
          {secondaryMessage && (
            <Typography variant="body1" className="secondary-msg-txt">
              {secondaryMessage}
            </Typography>
          )}
        </div>
      </div>
      {actions || (
        <>
          {btnText && (
            <div className="btn-row flex-center mt-4">
              <div className="btn-grid">
                <Button
                  startIcon={btnIcon || <AddIcon />}
                  variant="outlined"
                  onClick={onBtnClick}
                  color="primary"
                >
                  {btnText}
                </Button>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default AlertImageCard;
