import { Button } from "@material-ui/core";
import RefreshIcon from "@material-ui/icons/Refresh";
import SendIcon from "@material-ui/icons/Send";
import UpdateIcon from "@material-ui/icons/Update";
import AlertImageCard from "components/AlertImageCard/AlertImageCard";
import AlertImageCardContainer from "components/AlertImageCard/AlertImageCardContainer";
import { useGetConnectivity } from "containers/ErrorHandlers/useGetConnectivity";
import { useVersionUpdateStatus } from "modules/VersionUpdater/useVersionUpdateStatus";
import { SW, wbRegistration } from "modules/VersionUpdater/wb";
import React, { useEffect } from "react";
import { BROWSER } from "services/browser_info";
import { checkIsChunkLoadError } from "./ErrorBoundary";

export interface FallbackUIProps {
  error: Error;
  sendFeedback: () => void;
}

/**
 * Should be used only for pages and tabs
 * @param sendFeedback
 * @param error
 * @constructor
 */
export function DefaultFallbackUI({ sendFeedback, error }: FallbackUIProps) {
  const isChunkLoadError = checkIsChunkLoadError(error);
  const swEnabled = BROWSER.serviceWorkerEnabled;
  useGetConnectivity();
  useEffect(() => {
    wbRegistration?.checkForUpdates();
  }, []);
  /**
   * if a update available, should visible in the workspacebar
   */
  const { hasUpdate, applyUpdate } = useVersionUpdateStatus();

  if (isChunkLoadError && swEnabled) {
    // cache clearance issue
    if (hasUpdate) {
      // if an update available, we can ask the user to update,
      // reloading is unlikely solve the error
      //  feedback isn't useful either
      return (
        <AlertImageCardContainer>
          <AlertImageCard
            cardImgClass="new-version"
            severity="info"
            secondaryMessage=""
            message="Something went wrong. Try updating to the latest version"
            btnText="Update"
            onBtnClick={applyUpdate}
            btnIcon={<UpdateIcon />}
          />
        </AlertImageCardContainer>
      );
    } else {
      //  update may downloaded in few seconds
      //  otherwise (new version hasn't released yet but the n/w request has failed)
      // or n/w is preventing from downloading the update
      // safer to unregister the s/w
      // this will reload all tabs
      return (
        <AlertImageCardContainer>
          <AlertImageCard
            cardImgClass="complex-error"
            severity="info"
            secondaryMessage=""
            message="Something went wrong. Reloading the page might solve the issue."
            btnText="Refresh"
            onBtnClick={SW.cleanUpServiceWorkerAndReload}
            btnIcon={<RefreshIcon />}
          />
        </AlertImageCardContainer>
      );
    }
  }
  if (isChunkLoadError) {
    // no need for feedback
    // cache cleared by the user or failed due to n/w,
    // reloading is sufficient
    return (
      <AlertImageCardContainer>
        <AlertImageCard
          cardImgClass="server-down"
          severity="info"
          secondaryMessage=""
          message="Something went wrong. Reloading the page might solve the issue."
          btnText="Refresh"
          onBtnClick={BROWSER.clearAllBrowserCachesNReload}
          btnIcon={<RefreshIcon />}
        />
      </AlertImageCardContainer>
    );
  }
  // some unknown error in code
  // can ask for feedback
  // for now we can display a reload btn, TODO allow resetting page/tab
  // might be unneccessary to unregister sw
  return (
    <AlertImageCardContainer>
      <AlertImageCard
        cardImgClass="under-construction"
        severity="info"
        secondaryMessage=""
        message="Something went wrong. Reloading the page might solve the issue."
        actions={
          <div className="btn-row flex-center mt-4">
            <div className="btn-grid">
              <Button
                startIcon={<SendIcon />}
                variant="outlined"
                onClick={sendFeedback}
                color="primary"
              >
                Send Feedback
              </Button>
            </div>
            <div className="btn-grid">
              <Button
                variant="outlined"
                onClick={BROWSER.reload}
                color="primary"
              >
                Refresh
              </Button>
            </div>
          </div>
        }
      />
    </AlertImageCardContainer>
  );
}
