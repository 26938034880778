import Axios, { AxiosInstance, AxiosResponse } from "axios";
import { tokenEventsEmitter } from "services/onpremise/tokenService";
import { SentryService } from "services/sentry";
import { AUTH_ERRORS } from "./auth";

export function addResponseInterceptor(instance: AxiosInstance) {
  instance.interceptors.response.use(
    function onValidResponse(response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    async function onError(error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        const { data, status } = error.response as AxiosResponse;
        const displayMsg = (data && data.message) || "Something went wrong!";
        const errorCode = (data && data.errorCode) || "ERR_RESPONSE";
        if (process.env.REACT_APP_AUTH_ENV === "ON_PREM") {
          if (
            errorCode === AUTH_ERRORS.TOKEN_EXPIRED ||
            errorCode === AUTH_ERRORS.INVALID_TOKEN
          ) {
            tokenEventsEmitter.emit({ type: "TOKEN_EXPIRED" });
          }
        }
        throw new PXAPIError(displayMsg, "ERR_RESPONSE", {
          error,
          code: errorCode,
          sendReport: status < 400 || status > 408,
          resData: data,
        });
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        throw new PXAPIError("Network error!", "NETWORK_ERROR", {
          error,
          sendReport: false,
        });
      } else {
        // Something happened in setting up the request that triggered an Error
        throw new PXAPIError("Something went wrong!", "UNKOWN_REQ_ERR", {
          error,
        });
      }
    }
  );
}

interface PXAPIErrorInfo {
  /**
   * error object (axios)
   */
  error?: Error;
  /**
   * Predefined error codes from auth api
   */
  code?: string;
  /**
   * default `true`
   * */
  sendReport?: boolean;
  /**
   * request data object (fetch)
   */
  reqData?: any;
  /**
   * response data object
   */
  resData?: any;
}

export class PXAPIError extends Error {
  displayMsg: string;
  code?: string;
  cause: Error | null;
  responseData: any;
  constructor(displayMsg: string, msg: string, params: PXAPIErrorInfo = {}) {
    const { code, error, sendReport = true, reqData, resData } = params;
    super(`${PXAPIError.name}: ${msg}`);
    this.name = "APIError";
    this.displayMsg = displayMsg;
    this.code = code;
    this.responseData = resData;
    this.cause = error || null;
    if (sendReport)
      SentryService.reportWithScope(error || this, {
        request: reqData ? JSON.stringify(reqData, null, 2) : null,
        response: resData ? JSON.stringify(resData, null, 2) : null,
        error,
      });
  }
  getAxiosError() {
    if (!this.cause) return null;
    if (Axios.isAxiosError(this.cause)) {
      return this.cause;
    }
    return null;
  }
}
