import { useEffect } from "react";

/**
 *
 * @param fn run effect on mount
 */
export function useMount(fn: () => void) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(fn, []);
}
