import type firebase from "firebase/app";
import { APP_VERSION } from "services/app_version";
import { ConfigService } from "services/config";
import { Intercom } from "services/intercom";
import { SentryService } from "services/sentry";
import { noop } from "utls/noop";

/**
 * firebase only  recognize app version from `SCREEN_VIEW` events.
 */
const app_version = APP_VERSION.TAG || "unknown_version";

interface UserInfo {
  uid: string;
}
type State = "waiting" | "active" | "not-supported";

let state: State = "waiting";
let fbaInstance: firebase.analytics.Analytics | null = null;
let queue: Array<() => void> | null = [];

function trackEvent(eventName: string, metadata?: Record<string, any>) {
  try {
    fbaInstance?.logEvent(eventName, { ...metadata, app_version });
    Intercom.trackGAEvent(eventName);
  } catch (error) {
    SentryService.reportWithScope(error, { fn: "GA:trackEvent" });
  }
}

/**
 * usage
 * * <Button onClick={ ()=>{ GA.trackClick("env_add"); } } >Add</Button>
 * * <Button onClick={ GA.trackClick("env_add", ()=> {} ) } >Add</Button>
 */
function trackClick(eventName: string, fn: () => void): () => void;
function trackClick(eventName: string): void;
function trackClick(eventName: string, fn?: () => void) {
  const event = `clicked_${eventName}`;
  if (!fn) {
    return trackEvent(event);
  }
  return () => {
    try {
      fn();
    } catch (error) {
      SentryService.reportWithScope(error, { fn: "GA:trackClick" });
    }
    trackEvent(event);
  };
}

function enqueue(fn: () => {}) {
  queue?.push(fn);
}

function onLoad(instance: firebase.analytics.Analytics) {
  try {
    state = "active";
    fbaInstance = instance;
    queue?.forEach((fn) => fn());
  } catch (error) {
    SentryService.reportWithScope(error, { fn: "GA:onLoad" });
  }
}

function stop() {
  state = "not-supported";
  queue = null;
}

function onUserInit({ uid }: UserInfo) {
  try {
    fbaInstance?.setUserId(uid);
  } catch (error) {
    SentryService.reportWithScope(error, { fn: "GA:onUserInit" });
  }
}

/**
 * Track page/tab view
 * @param page page title
 * @param tab tab title
 */
function trackPage(page: string, tab?: string) {
  try {
    const pageTab = tab ? `${page} (${tab})` : page;
    fbaInstance?.logEvent("screen_view", {
      app_name: "Platformer Console",
      screen_name: pageTab,
      app_version,
    });
    const pageTabEvent = tab ? `${page}_(${tab})` : `${page}`;
    const eventName = `viewed_${tab ? "tab" : "page"}_${pageTabEvent}`
      .toLowerCase()
      .replaceAll(/\s+/g, "_");
    fbaInstance?.logEvent(eventName, { app_version });
    // intercom also tracks page views by default from location changes
    Intercom.trackGAEvent(eventName);
  } catch (error) {
    SentryService.reportWithScope(error, { fn: "GA:trackPage" });
  }
}

const _GA = {
  onLoad,
  stop,
  enqueue,
  onUserInit,
  trackPage,
  trackEvent,
  trackClick,
};

/**
 * functions that need to be proxied until firebase analytics is loaded.
 * `trackClick` mustn't be proxied.
 */
const fns: ReadonlyArray<
  Exclude<keyof typeof _GA, "onLoad" | "stop" | "enqueue" | "trackClick">
> = ["onUserInit", "trackPage", "trackEvent"];

export const GoogleAnalytics = /* #__PURE__ */ new Proxy(_GA, {
  get: function (target, prop, receiver) {
    if (fns.includes(prop as any)) {
      if (ConfigService.CONFIG.FIREBASE_EVENTS !== "ENABLED") return noop;
      if (state === "not-supported") return noop;
      if (state === "waiting") {
        return (...args: any[]) => {
          enqueue(() => Reflect.get(target, prop, receiver)(...args));
        };
      }
    }
    return Reflect.get(target, prop, receiver);
  },
});

export type GAType = typeof GoogleAnalytics;
