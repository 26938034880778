import { waitFor } from "utls/async_utils";

/**
 * cache all files as soon as the page loads,
 * if succeeds, should prevent chunk load errors on future updates (unless user clears cache),
 * since cache headers are setup, network requests will be sent only the first time.
 */
export async function cacheAssets() {
  try {
    await waitFor(10000);
    const path = "/asset-manifest.json";
    let res = await fetch(path);
    if (!res.ok) {
      await waitFor(5000);
      res = await fetch(path);
    }
    if (!res.ok) return;
    const assets: Manifest = (await res.json()) as any;
    const filePaths = Object.values(assets.files).filter(
      (f) => f.endsWith(".js") || f.endsWith(".css")
    );
    const cacheName = `${Date.now()}-${performance.now()}`;
    const tempCache = await caches.open(cacheName);
    await tempCache.addAll(filePaths);
    await caches.delete(cacheName);
  } catch (e) {}
}

interface Manifest {
  files: Record<string, string>;
}
