import { PageLoader } from "components/PageLoader/PageLoader";
import { useMount } from "hooks/useMount";
import * as React from "react";
import { authMachineService } from "services/auth/authMachine/authMachine";

interface Props {
  /**
   * to handle cleanup in cases where inifinite loops may occur
   */
  action?(): void;
}

export function InvalidRoute({ action }: Props) {
  useMount(() => {
    if (action) {
      action();
    }
    authMachineService.send("INVALID_ROUTE_DETECTED");
  });
  return <PageLoader />;
}
