import { OnPremUser } from "api/auth/auth_endpoints";
import {
  OrgId,
  ProjectId,
  UserDocument,
  UserId,
} from "shared/interfaces/document_interfaces";
import { FbUser } from "./../../firebase/FbUser";

export const AUTH_STATES = {
  PAGE_LOAD: "page_load",
  CHECK_PRV_SESSION: "check_previous_session",
  INIT_USER_DATA: "initializing_user_data",
  AWAIT_LOGIN_REGISTER: "await_login_register",
  LOGIN_WITH_EMAIL: "login_with_email",
  LOGGED_IN: "logged_in",
  CLI_LOGIN: "cli_login",
  LOGGING_OUT: "LOGGING_OUT",
  CRITICAL_ERROR: "critical_error",
} as const;

export interface AuthMachineSchema {
  states: {
    [AUTH_STATES.PAGE_LOAD]: {};
    [AUTH_STATES.CHECK_PRV_SESSION]: {};
    [AUTH_STATES.INIT_USER_DATA]: {};
    [AUTH_STATES.AWAIT_LOGIN_REGISTER]: {
      states: {
        idle: {};
        signInWithPopup: {};
      };
    };
    [AUTH_STATES.CLI_LOGIN]: {};
    [AUTH_STATES.LOGGED_IN]: {};
    [AUTH_STATES.LOGGING_OUT]: {};
    [AUTH_STATES.CRITICAL_ERROR]: {};
  };
}

type ID_PROVIDERS = "GOOGLE" | "GITHUB";

export interface LoginWithProvider {
  type: "LOGIN_WITH_PROVIDER";
  provider: ID_PROVIDERS;
}

interface LoginWithEmailSuccess {
  type: "LOGIN_WITH_EMAIL_SUCCESS";
  data: FbUser | OnPremUser;
}

interface UserSnapshotRecieved {
  type: "USER_SNAPSHOT_RECIEVED";
  snapshot: UserDocument;
}

export interface OrgProjectChangedEvent {
  type: "ORG_PROJECT_CHANGE";
  orgId: OrgId | null;
  projectId: ProjectId | null;
}
interface LogoutEvent {
  type: "LOGOUT";
}

interface LogoutTabSync {
  type: "LOGOUT_TAB_SYNC";
}
interface LoginTabSyncEvent {
  type: "LOGIN_TAB_SYNC";
}

/**
 * On invalid routes
 */
interface InvalidRouteDetectedEvent {
  type: "INVALID_ROUTE_DETECTED";
}

export type AuthMachineEvent =
  | LoginWithEmailSuccess
  | LoginWithProvider
  | LogoutEvent
  | UserSnapshotRecieved
  | InvalidRouteDetectedEvent
  | OrgProjectChangedEvent
  | LoginTabSyncEvent
  | LogoutTabSync;
export interface AuthMachineContext {
  userId: UserId | null;
  initialOrgId: string | null;
  initialProjId: string | null;
  initialPath: string | null;
  criticalError: null | Error;
}
