const AUTH_ERRORS = {
  UNAUTHORIZED: "UNAUTHORIZED",
  REGISTRATION_FAILED: "REGISTRATION_FAILED",
  EMAIL_ALREADY_IN_USE: "EMAIL_ALREADY_IN_USE",
  HEADER_SHOULD_INCLUDE_PRJECT_AND_ORGANIZATION_IDS:
    "HEADER_SHOULD_INCLUDE_PRJECT_AND_ORGANIZATION_IDS",
  TOKEN_EXPIRED: "TOKEN_EXPIRED",
  EMAIL_NOT_VERIFIED: "EMAIL_NOT_VERIFIED",
  INTERNAL_ERROR: "INTERNAL_ERROR",
  OPERATION_FAILED: "OPERATION_FAILED",
  ACCESS_DENIED: "ACCESS_DENIED",
  INVALID_USER_ID: "INVALID_USER_ID",
  INVALID_PROJECT_ID: "INVALID_PROJECT_ID",
  INVALID_PERMISSION_ID: "INVALID_PERMISSION_ID",
  INVALID_ROLE_ID: "INVALID_ROLE_ID",
  INVALID_CUSTOM_ROLE_ID: "INVALID_CUSTOM_ROLE_ID",
  INVALID_ORGANIZATION_ID: "INVALID_ORGANIZATION_ID",
  INVALID_BILLING_ACCOUNT: "INVALID_BILLING_ACCOUNT",
  TOKEN_CREATION_FAILED: "TOKEN_CREATION_FAILED",
  FIELD_VALIDATION_ERRORS: "FIELD_VALIDATION_ERRORS",
  BAD_REQUEST: "BAD_REQUEST",
  REQUESTED_RESOURCE_NOT_FOUND: "REQUESTED_RESOURCE_NOT_FOUND",
  USER_ALREADY_EXISTS: "USER_ALREADY_EXISTS",
  PERMISSION_DENIED: "PERMISSION_DENIED",
  EMPTY_ROLE_IDS: "EMPTY_ROLE_IDS",
  INVALID_HOST: "INVALID_HOST",
  EMPTY_ORGANIZATIONS: "EMPTY_ORGANIZATIONS",
  EMPTY_PROJECTS: "EMPTY_PROJECTS",
  INVALID_REQUEST_FORMAT: "INVALID_REQUEST_FORMAT",
  VERSION_SHOULD_BE_UPGRADE: "VERSION_SHOULD_BE_UPGRADE",
  INVALID_SERVICE_ACCOUNT: "INVALID_SERVICE_ACCOUNT",
  INVALID_DATE_FORMAT: "INVALID_DATE_FORMAT",
  INVALID_DATE: "INVALID_DATE",
  INVALID_TOKEN: "INVALID_TOKEN",
  INVALID_KEY: "INVALID_KEY",
  EXPIRED_KEY: "EXPIRED_KEY",
  WEAK_PASSWORD: "WEAK_PASSWORD",
  USER_NOT_FOUND: "USER_NOT_FOUND",
  INVALID_PASSWORD: "INVALID_PASSWORD",
  INVALID_EMAIL: "INVALID_EMAIL",
  EMAIL_ALREADY_VERIFIED: "EMAIL_ALREADY_VERIFIED",
  INVALID_ENV_ID: "INVALID_ENV_ID",
  USER_NOT_IN_THE_PROJECT: "USER_NOT_IN_THE_PROJECT",
  INVALID_DOMAIN: "INVALID_DOMAIN",
  DOMAIN_ALREADY_EXISTS: "DOMAIN_ALREADY_EXISTS",
  INVALID_SUPPORT_EMAIL: "INVALID_SUPPORT_EMAIL",
  TXT_RECORD_NOT_FOUND: "TXT_RECORD_NOT_FOUND",
  SIGNUP_DISABLED: "SIGNUP_DISABLED",
};

export { AUTH_ERRORS };
