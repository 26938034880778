import { ConfigService } from "services/config";
import { ENV } from "services/env_variables";
import { FEATURES } from "services/feature_detection";
import { TabSync } from "services/tabSync";
import { noop } from "utls/noop";

export const BROWSER = (function initBrowserInfo() {
  const _isNewBrowser = !localStorage.getItem("pxid");
  function reload() {
    window.location.reload();
  }
  return {
    get clientId() {
      const id = localStorage.getItem("pxid");
      if (id) return id;
      const newId = generateId();
      localStorage.setItem("pxid", newId);
      return newId;
    },
    get sessionId() {
      const id = sessionStorage.getItem("tid");
      if (id) return id;
      const newId = generateId();
      sessionStorage.setItem("tid", newId);
      return newId;
    },
    get isNewBrowser() {
      return _isNewBrowser;
    },
    async checkInternetConnectivity() {
      try {
        const res = await fetch("/release-info.json", { method: "HEAD" });
        if (res.ok) return "connected";
      } catch (error) {}
      return "not-connected";
    },
    reload,
    /**
     * Whether a service worker can be expected to be enabled,
     * Might not have activated if some error occurred
     */
    get serviceWorkerEnabled() {
      if (process.env.NODE_ENV !== "production") return false;
      if (!FEATURES.SERVICE_WORKER_SUPPORTED) return false;
      if (ENV.serviceWorkerEnabledInEnv) return true;
      if (!ConfigService.initialized) return false;
      return ConfigService.CONFIG.USE_SW === "ENABLED";
    },
    /**
     * precache on page load
     * enabled by default
     */
    get precacheEnabled() {
      if (process.env.NODE_ENV !== "production") return false;
      if (!ConfigService.initialized) return false;
      return ConfigService.CONFIG.USE_PRECACHE !== "DISABLED";
    },
    /**
     * Clears all http cache, storage apis and reloads the tabs.
     * won't work on safari
     * fallback to reload tab
     */
    async clearAllBrowserCachesNReload() {
      await fetch("/cache_clear.html").catch(noop);
      TabSync.notifyOtherTabs("RELOAD_MSG");
      reload();
    },
  };
})();

function generateId() {
  try {
    return `${1e7}-${1e3}-${4e3}-${8e3}-${1e11}`.replace(/[018]/g, (c: any) =>
      (
        c ^
        (crypto.getRandomValues(new Uint8Array(1))[0]! & (15 >> (c / 4)))
      ).toString(16)
    );
  } catch (e) {}
  try {
    let tid = `${4e3}`;
    if (window.performance) {
      tid = `${Math.round(performance.now())}`;
    }
    return `${Date.now()}-${tid}-${1e11}`;
  } catch (e) {
    return `${Date.now()}-${1e15}`;
  }
}
