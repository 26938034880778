import React, { useMemo } from "react";
import { useErrorHandler } from "react-error-boundary";
import {
  QueryStatus,
  useQuery as useQuery3,
  UseQueryOptions,
  UseQueryResult,
} from "react-query";
import { isPermissionError } from "shared/errors/commonFormErrors";
import { createCtx } from "utls/createReactContext";

type TypedQueryFunctionArgs = readonly [unknown, ...unknown[]];
type TypedQueryFunction<
  TResult,
  TArgs extends TypedQueryFunctionArgs = TypedQueryFunctionArgs
> = (...args: TArgs) => TResult | Promise<TResult>;

export type { UseQueryResult as QueryResultV2 };
export type { CustomQueryConfig as QueryConfigV2 };
export type { QueryStatus as QueryStateV2 };

export const QueryStatusV2 = {
  Idle: "idle",
  Loading: "loading",
  Error: "error",
  Success: "success",
} as const;

export interface QueryCustomConfig {
  /**
   * @default if outside of `Page` `false` else `true`
   */
  usePermissionErrorBoundary: boolean;
}

interface CustomQueryConfig<TResult, TError = unknown>
  extends UseQueryOptions<TResult, TError>,
    Partial<QueryCustomConfig> {}

/**
 *
 * @param queryKey
 * @param fn
 * @param config
 * @param additonalQueryKeys additonal data that need to be added to  the key,
 * eg: `projectId` for resources that belong to a project, when `projectId` is unused by the function,
 * but added by the inteceptor
 */
export function useTypedQueryV2<
  TResult,
  TKey extends TypedQueryFunctionArgs,
  TError = Error
>(
  queryKey: TKey,
  fn: TypedQueryFunction<TResult, TKey>,
  config?: CustomQueryConfig<TResult, TError>,
  additonalQueryKeys: Array<string | number> = []
) {
  const query = useQuery3(
    [...queryKey, ...additonalQueryKeys],
    () => fn(...queryKey),
    config
  );
  const permissionError = isPermissionError(query.error) ? query.error : null;
  const defaultConfig = useCustomConfig();
  const throwPermissionError =
    config?.usePermissionErrorBoundary ??
    defaultConfig.usePermissionErrorBoundary;
  useErrorHandler(throwPermissionError && permissionError);
  return query;
}

export function useQueryV3<
  TResult,
  TKey extends TypedQueryFunctionArgs,
  TError = Error
>(
  queryKey: TKey,
  fn: () => Promise<TResult>,
  config?: CustomQueryConfig<TResult, TError>
) {
  const query = useQuery3(queryKey, fn, config);
  const permissionError = isPermissionError(query.error) ? query.error : null;
  const defaultConfig = useCustomConfig();
  const throwPermissionError =
    config?.usePermissionErrorBoundary ??
    defaultConfig.usePermissionErrorBoundary;
  useErrorHandler(throwPermissionError && permissionError);
  return query;
}

export function QueryCustomConfigProvider({
  config,
  children,
}: React.PropsWithChildren<{ config: Partial<QueryCustomConfig> }>) {
  const parentConfig = useCustomConfig();
  const mergedConfig = useMemo(() => ({ ...parentConfig, ...config }), [
    config,
    parentConfig,
  ]);
  return (
    <CustomConfigProvider value={mergedConfig}>{children}</CustomConfigProvider>
  );
}

const [CustomConfigProvider, useCustomConfig] = createCtx<QueryCustomConfig>(
  "query-custom-config",
  { usePermissionErrorBoundary: false }
);
