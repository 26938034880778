import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";
import { APP_VERSION } from "services/app_version";
import { ConfigService } from "services/config";
import { BROWSER } from "./../browser_info";
import { FEATURES } from "./../feature_detection";

const { showReportDialog, addBreadcrumb, Severity } = Sentry;
export { showReportDialog, addBreadcrumb, Severity };

const NETWORK_BREADCRUMB_INTERVAL = 5 * 60 * 1000;

let intervalId: any; // incorrect intervalid type

export function init() {
  if (!ConfigService.initialized) return;
  if (ConfigService.CONFIG.SENTRY_DSN === "REPORTING_DISABLED") {
    return;
  }
  const apiDomains = getApiDomains();
  Sentry.init({
    dsn: ConfigService.CONFIG.SENTRY_DSN,
    release: `${APP_VERSION.RELEASE}`,
    integrations: [
      new Integrations.BrowserTracing({
        tracingOrigins: [
          "localhost", // cli login
          window.location.hostname,
          ...apiDomains,
          /^\//,
        ],
        traceFetch: false,
        traceXHR: false,
        startTransactionOnLocationChange: false,
        startTransactionOnPageLoad: true,
        maxTransactionDuration: 60,
      }),
    ],
    tracesSampleRate: ConfigService.CONFIG.SENTRY_SAMPLE_RATE ?? 1.0,
    ignoreErrors: ConfigService.CONFIG.SENTRY_IGNORE_ERRORS || [],
  });
  configureScope();
  if (BROWSER.isNewBrowser) {
    reportMsg("client_init");
  }
  intervalId = setInterval(async () => {
    const onlineStatus = await BROWSER.checkInternetConnectivity();
    Sentry.addBreadcrumb({
      category: "online-status",
      message: onlineStatus,
      level: Sentry.Severity.Info,
    });
  }, NETWORK_BREADCRUMB_INTERVAL);
}

function configureScope() {
  Sentry.configureScope((scope) => {
    scope.setTag(
      "sw_support",
      FEATURES.SERVICE_WORKER_SUPPORTED ? "supported" : "not-supported"
    );
    if (FEATURES.SERVICE_WORKER_SUPPORTED) {
      scope.setTag("initial_sw_state", FEATURES.SERVICE_WORKER_STATE);
    }
    scope.setTag("client_guid", BROWSER.clientId);
    scope.setTag("session_id", BROWSER.sessionId);
    const sessionStart = sessionStorage.getItem("X_CONFIG_LOADED");
    if (sessionStart) {
      scope.setTag(
        "session_start",
        new Date(parseInt(sessionStart, 10)).toUTCString()
      );
    }
    const sessionENV = sessionStorage.getItem("ENV");
    if (sessionENV) {
      scope.setTag("environment", sessionENV);
    }
    scope.setTag("domain", window.location.origin.replace("https://", ""));
  });
}

/**
 * get domain names of API base urls
 * https://docs.sentry.io/platforms/javascript/performance/included-instrumentation/
 * * "Access-Control-Allow-Headers: sentry-trace"
 */
function getApiDomains() {
  const domains = Object.values(ConfigService.API)
    .map((url) => {
      try {
        return new URL(url).hostname;
      } catch (error) {
        return "INVALID_URL";
      }
    })
    .filter((v) => v !== "INVALID_URL");
  return Array.from(new Set(domains));
}

export function close(waitMs: number) {
  clearInterval(intervalId);
  return Sentry.close(waitMs);
}

export function login(user: Sentry.User) {
  Sentry.configureScope(function (scope) {
    scope.setUser(user);
  });
}

export function logout() {
  Sentry.configureScope((scope) => scope.setUser(null));
}

export function setTag(tagName: string, value: string) {
  Sentry.configureScope(function (scope) {
    scope.setTag(tagName, value);
  });
}

export function reportWithScope(
  error: any,
  extraInfo?: any,
  onCaptureSuccess?: (eventId: string) => void
) {
  Sentry.withScope((scope) => {
    extraInfo && scope.setExtras(extraInfo);
    const eventId = Sentry.captureException(error);
    onCaptureSuccess && onCaptureSuccess(eventId);
  });
}

export function reportMsg(msg: string, extraInfo?: any) {
  Sentry.withScope((scope) => {
    extraInfo && scope.setExtras(extraInfo);
    Sentry.captureMessage(msg);
  });
}

export function reportMsgOnce(key: string, msg: string, extraInfo?: any) {
  // FIXME send msg once per unique key
}
