import { OnPremUser } from "api/auth/auth_endpoints";
import { AuthMainActions } from "services/auth/auth_actions_interfaces";
import { firebaseAuthMainActions } from "services/firebase/fb_auth_main_actions";
import { FbUser } from "services/firebase/FbUser";
import { OnPremiseAuth } from "services/onpremise/on_premise_auth";

let authMainActions: AuthMainActions<OnPremUser | FbUser>;

if (process.env.REACT_APP_AUTH_ENV === "ON_PREM") {
  authMainActions = OnPremiseAuth;
} else {
  authMainActions = firebaseAuthMainActions;
}
export { authMainActions };
