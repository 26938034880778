const CREATE_ORG = "created-organization";
const CREATE_PROJ = "created-project";
const CREATE_ENV = "created-environment";
const CREATE_NAMESPACE = "created-namespace";
const CREATE_APP = "created-application";
const CREATE_BILLING_ACC = "created-billing-account";
const CREATE_APP_ENV = "created-app-environment";
const IMPORT_APP_ENV = "imported-app-environment";
const SYNC_APP_ENV = "synced-app-environment";
const CREATE_APP_CONTAINER = "created-container";
const CREATE_IMAGE_REGISTRY = "created-image-registry";
const CREATE_REGISTRY_CREDENTIAL = "created-registry-credential";
const CONNECT_PRIVATE_CLUSTER = "connected-private-cluster";
const SWITCH_ORGANIZATION = "switched-organization";
const SWITCH_PROJECT = "switched-project";

export const IntercomEvents = {
  CREATE_ORG,
  CREATE_PROJ,
  CREATE_ENV,
  CREATE_NAMESPACE,
  CREATE_APP,
  CREATE_BILLING_ACC,
  CREATE_APP_ENV,
  CREATE_APP_CONTAINER,
  CREATE_IMAGE_REGISTRY,
  CREATE_REGISTRY_CREDENTIAL,
  IMPORT_APP_ENV,
  SYNC_APP_ENV,
  CONNECT_PRIVATE_CLUSTER,
  SWITCH_ORGANIZATION,
  SWITCH_PROJECT,
} as const;
