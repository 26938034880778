import { UserProjectMapping } from "shared/interfaces/document_interfaces";
import createHook from "zustand";
import createStore from "zustand/vanilla";
import { ProjectId } from "./../shared/interfaces/document_interfaces";

export type ProjectState =
  | "init"
  | "empty"
  | "loading"
  | "valid"
  | "invalid"
  | "error";

type IProjectModel = {
  projectId: ProjectId | null;
  validity: ProjectState;
  document: UserProjectMapping | null;
  setProject(projId: ProjectId): void;
  setEmpty(): void;
  updateDocument(mapping: UserProjectMapping): void;
  reset(): void;
  setValidity(validity: ProjectState): void;
  /**
   * to be passed as env header
   */
  activeEnvId: string | null;
  setActiveEnv(id: string | null): void;
  /**
   * last active env, to autoselect env
   */
  lastEnvId: string | null;
  deleteStates: Record<string, "deleting" | "deleted" | null>;
  setDeleteState(
    ProjectId: string,
    state: "deleting" | "deleted" | "none"
  ): void;
};

export const projectStore = createStore<IProjectModel>((set, get) => ({
  projectId: null,
  document: null,
  validity: "init",
  deleteStates: {},
  setProject(incomingProjId) {
    if (get().projectId === incomingProjId) return;
    set({
      projectId: incomingProjId,
      validity: "loading",
      document: null,
      activeEnvId: null,
      lastEnvId: null,
    });
  },
  setEmpty() {
    set({
      projectId: null,
      document: null,
      validity: "empty",
      activeEnvId: null,
      lastEnvId: null,
    });
  },
  updateDocument(payload) {
    set({ document: payload });
  },
  setValidity(val) {
    set({ validity: val });
  },
  reset() {
    set({
      projectId: null,
      document: null,
      validity: "init",
      activeEnvId: null,
      lastEnvId: null,
    });
  },
  activeEnvId: null,
  setActiveEnv(val) {
    if (val === null) {
      set({ activeEnvId: val });
    } else {
      set({ activeEnvId: val, lastEnvId: val });
    }
  },
  lastEnvId: null,
  setDeleteState(projectId, state) {
    if (state === "none") {
      set({ deleteStates: { ...get().deleteStates, [projectId]: null } });
    } else {
      set({ deleteStates: { ...get().deleteStates, [projectId]: state } });
    }
  },
}));

export const useProjectStore = createHook(projectStore);
