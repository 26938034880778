import { AUTH_ERRORS } from "api/auth/error_codes";
import { PXAPIError } from "api/responseInterceptor";
import { noop } from "utls/noop";
import { JoiError } from "./../../shared/joi_error";
import { authInstance } from "./authInstance";

export interface SignInDetails {
  email: string;
  password: string;
}

export interface SignInError {
  success: false;
  errorCode:
    | "NETWORK_ERROR"
    | "SERVER_ERROR"
    | "INVALID_CREDENTIALS"
    | "EMAIL_NOT_VERIFIED"
    | "UNKNOWN_ERROR"
    | "INVALID_EMAIL_FORMAT";
}

export interface SignInSuccess {
  success: true;
  token: string;
}

const skipHeaderAdd = {
  metadata: { skipHeaderAdd: true },
} as any;
export async function signInWithEmailAndPassword(
  info: SignInDetails
): Promise<SignInSuccess | SignInError> {
  const { email, password } = info;
  try {
    const res = await authInstance.post(
      "/user/login",
      { email, password },
      skipHeaderAdd
    );
    if (res.data.success) {
      return { success: true, token: res.data.data.id_token };
    }
    return { success: false, errorCode: "SERVER_ERROR" };
  } catch (e) {
    if (!(e instanceof PXAPIError)) {
      return { success: false, errorCode: "UNKNOWN_ERROR" };
    } else if (e.code) {
      const {
        USER_NOT_FOUND,
        INVALID_PASSWORD,
        EMAIL_NOT_VERIFIED,
        UNAUTHORIZED,
        FIELD_VALIDATION_ERRORS,
      } = AUTH_ERRORS;
      switch (e.code) {
        case USER_NOT_FOUND:
        case INVALID_PASSWORD:
        case UNAUTHORIZED:
          return { success: false, errorCode: "INVALID_CREDENTIALS" };
        case EMAIL_NOT_VERIFIED:
          return { success: false, errorCode: "EMAIL_NOT_VERIFIED" };
        case FIELD_VALIDATION_ERRORS: {
          const error = e.responseData as JoiError<"email" | "password">;
          if (error.errors[0].field.includes("email")) {
            return { success: false, errorCode: "INVALID_EMAIL_FORMAT" };
          }
          return { success: false, errorCode: "INVALID_CREDENTIALS" };
        }
      }
    } else if (e.message === "NETWORK_ERROR") {
      return { success: false, errorCode: "NETWORK_ERROR" };
    }
    return { success: false, errorCode: "UNKNOWN_ERROR" };
  }
}

export interface SignUpDetails {
  username: string;
  email: string;
  password: string;
}

export interface SignUpError {
  success: false;
  errorCode:
    | "NETWORK_ERROR"
    | "SERVER_ERROR"
    | "UNKNOWN_ERROR"
    | "EMAIL_ALREADY_IN_USE"
    | "WEAK_PASSWORD"
    | "INVALID_EMAIL_FORMAT"
    | "INVALID_USERNAME_FORMAT";
}

export interface SignUpSuccess {
  success: true;
}

export async function signUp(
  info: SignUpDetails
): Promise<SignUpSuccess | SignUpError> {
  const { email, password, username } = info;
  try {
    const res = await authInstance.post(
      "/user/register",
      { email, password, username },
      skipHeaderAdd
    );
    // validate status
    if (res.data && res.data.success) return { success: true };
    return { success: false, errorCode: "UNKNOWN_ERROR" };
  } catch (e) {
    if (!(e instanceof PXAPIError)) {
      return { success: false, errorCode: "UNKNOWN_ERROR" };
    } else if (e.code) {
      const {
        WEAK_PASSWORD,
        EMAIL_ALREADY_IN_USE,
        FIELD_VALIDATION_ERRORS,
      } = AUTH_ERRORS;
      switch (e.code) {
        case WEAK_PASSWORD:
          return { success: false, errorCode: "WEAK_PASSWORD" };
        case EMAIL_ALREADY_IN_USE:
          return { success: false, errorCode: "EMAIL_ALREADY_IN_USE" };
        case FIELD_VALIDATION_ERRORS: {
          const error = e.responseData as JoiError<
            "email" | "password" | "username"
          >;
          const fieldNames = error.errors[0].field;
          if (fieldNames.includes("username")) {
            return { success: false, errorCode: "INVALID_USERNAME_FORMAT" };
          }
          if (fieldNames.includes("password")) {
            return { success: false, errorCode: "WEAK_PASSWORD" };
          }
          if (fieldNames.includes("email")) {
            return { success: false, errorCode: "INVALID_EMAIL_FORMAT" };
          }
        }
      }
    } else if (e.message === "NETWORK_ERROR") {
      return { success: false, errorCode: "NETWORK_ERROR" };
    }
    return { success: false, errorCode: "UNKNOWN_ERROR" };
  }
}

export async function updateLastLogin() {
  setTimeout(() => {
    authInstance.put("/user/logintime").catch(noop);
  }, 8000);
}

export async function resendConfirmationEmail(
  info: SignInDetails
): Promise<void> {
  const { email, password } = info;
  const res = await authInstance.post(
    "/user/send/emailverification",
    { email, password },
    skipHeaderAdd
  );
  if (!res.data.success) {
    throw new Error("RESEND_FAILED_WITH_200");
  }
}

/////// on prem endpoints ////////

/**
 * https://fusionauth.io/docs/v1/tech/apis/login/#authenticate-a-user
 */
export interface OnPremUser {
  email: string;
  username: string;
  name: string;
  id: string;
  imageUrl: string;
  active: boolean;
}

export interface OnPremSignInSuccess {
  success: true;
  token: string;
  refreshToken: string;
  user: OnPremUser;
}

export async function signInOnPrem(
  info: SignInDetails
): Promise<OnPremSignInSuccess | SignInError> {
  const { email, password } = info;
  try {
    const res = await authInstance.post(
      "/user/login",
      { email, password },
      skipHeaderAdd
    );
    const apiRes = res.data;
    if (apiRes.success) {
      return { success: true, ...apiRes.data };
    }
    return { success: false, errorCode: "SERVER_ERROR" };
  } catch (e) {
    if (!(e instanceof PXAPIError)) {
      return { success: false, errorCode: "UNKNOWN_ERROR" };
    } else if (e.code) {
      const {
        USER_NOT_FOUND,
        INVALID_PASSWORD,
        UNAUTHORIZED,
        FIELD_VALIDATION_ERRORS,
      } = AUTH_ERRORS;
      switch (e.code) {
        case USER_NOT_FOUND:
        case INVALID_PASSWORD:
        case UNAUTHORIZED:
          return { success: false, errorCode: "INVALID_CREDENTIALS" };
        case FIELD_VALIDATION_ERRORS: {
          const error = e.responseData as JoiError<"email" | "password">;
          if (error.errors[0].field.includes("email")) {
            return { success: false, errorCode: "INVALID_EMAIL_FORMAT" };
          }
          return { success: false, errorCode: "INVALID_CREDENTIALS" };
        }
      }
    } else if (e.message === "NETWORK_ERROR") {
      return { success: false, errorCode: "NETWORK_ERROR" };
    }
    return { success: false, errorCode: "UNKNOWN_ERROR" };
  }
}

export interface TokenRefreshSuccess {
  success: true;
  token: string;
  refreshToken: string;
}

const skipTokenAdd: any = {
  metadata: { skipToken: true },
};

export async function getNewOnPremToken({
  refreshToken,
  token,
}: {
  refreshToken: string;
  token: string;
}): Promise<TokenRefreshSuccess> {
  const res = await authInstance.post(
    "/user/newtoken",
    { refresh_token: refreshToken, id_token: token },
    skipTokenAdd
  );
  return res.data.data;
}
