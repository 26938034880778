import { FormApi, FORM_ERROR } from "final-form";
import arrayMutators from "final-form-arrays";
import createDecorator from "final-form-focus";
import { useMount } from "hooks/useMount";
import React, { useReducer, useRef } from "react";
import { Form as FinalForm, FormProps, FormSpy } from "react-final-form";
import { isPermissionError } from "shared/errors/commonFormErrors";

const focusOnErrors = createDecorator();

const initialData = {};
const debugFn = process.env.NODE_ENV !== "production" ? console.log : undefined;

interface FormContainerProps<T> {
  formId?: string;
  initialValues?: any;
  validate?: FormProps["validate"];
  validateOnBlur?: FormProps["validateOnBlur"];
  debug?: boolean;
  onSubmit(values: T): void | Promise<any>;
  setHasChanges?(hasUnsavedChanges: boolean): void;
  errorMapper?: ErrorMapper | null;
  className?: string;
  apiRef?: FormApiRef<T>;
}
export function FormContainer<T = any>({
  formId,
  setHasChanges,
  initialValues = initialData,
  validate,
  validateOnBlur,
  onSubmit,
  debug = false,
  className = "",
  children: formContent,
  errorMapper = defaultErrorMapper,
  apiRef,
}: React.PropsWithChildren<FormContainerProps<T>>) {
  // use key to reset form instance
  const [formKey, updateFormKey] = useReducer(Date.now, Date.now());
  return (
    <FinalForm
      key={formKey}
      onSubmit={getSubmitFn<T>(onSubmit, errorMapper)}
      subscription={{}}
      decorators={[focusOnErrors] as any}
      mutators={{ ...arrayMutators }}
      initialValues={initialValues}
      validate={validate}
      validateOnBlur={validateOnBlur}
      debug={debug ? debugFn : undefined}
      render={({ handleSubmit, form }) => {
        return (
          <>
            {apiRef && (
              <RunOnMount
                fn={() => {
                  apiRef.current = form;
                }}
              />
            )}
            <form
              className={`w-100 ${className}`}
              id={formId}
              onSubmit={handleSubmit}
              onReset={(e) => {
                e.preventDefault();
                updateFormKey();
              }}
            >
              {formContent}
            </form>
            {setHasChanges && (
              <FormSpy
                subscription={{ dirty: true }}
                onChange={({ dirty }) => setHasChanges(dirty)}
              />
            )}
          </>
        );
      }}
    />
  );
}

function getSubmitFn<FormData>(
  submitFn: FormContainerProps<FormData>["onSubmit"],
  mapperFn: ErrorMapper | null
) {
  if (mapperFn === null) return submitFn;
  return async function errorMappedSubmit(data: FormData) {
    try {
      // if succeeded, result shouldn't reach the form,
      // otherwise it isconsidered as an error by the form
      await submitFn(data);
    } catch (err) {
      return mapperFn(err);
    }
  };
}

/**
 * An error related to entire form
 */
export class FormError extends Error {
  errorCode: string;
  errMsg: string;
  constructor(errorCode: string, errMsg: string) {
    super(errorCode);
    this.errorCode = errorCode;
    this.errMsg = errMsg;
  }
}

/**
 * Field level errors
 */
export class FormDataError extends Error {
  errorCode: string;
  errObject: FormErrorObject;
  constructor(
    errObject: FormErrorObject,
    errorCode: string = "Form field error"
  ) {
    super(errorCode);
    this.errObject = errObject;
    this.errorCode = errorCode;
  }
}

type FormErrorObject = Record<string, any>;

/**
 * Handle API mismatch between `useMutation` and FinalForm
 * * React query requires us to throw an error in case of an error.
 * eg: serve/pre-submit validation errors
 * * Final form expects form errors in object format,
 * thrown errors are only for network/server errors.
 * eg: {[FORM_ERROR]: "<Error-Msg>"} or { [<field_name>]: "<Error-Msg>" }
 * * if an appropriate error message cannot be determined throw an error,
 * should display a generic error msg using `useMutation`'s error state
 */
type ErrorMapper = (error: any) => FormErrorObject;

function defaultErrorMapper(error: any): FormErrorObject {
  if (error instanceof FormError && error.errMsg) {
    return { [FORM_ERROR]: error.errMsg };
  }
  if (error instanceof FormDataError) {
    return error.errObject;
  }
  if (isPermissionError(error)) {
    const msg = "You don't have sufficient permissions to perform this action.";
    return { [FORM_ERROR]: msg };
  }
  throw error;
}

/**
 * use form api outside of `FormContainer`,
 * will be only available after first mount of `FormContainer`,
 */
export function useFormApiRef<T = any>() {
  return useRef<FormApi<T>>();
}
export type FormApiRef<T> = React.MutableRefObject<
  FormApi<T, Partial<T>> | undefined
>;
function RunOnMount({ fn }: { fn: () => void }) {
  useMount(fn);
  return null;
}
