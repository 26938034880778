import { APP_VERSION, SW_RELEASE_INFO } from "services/app_version";
import { BROWSER } from "services/browser_info";
import { SentryService } from "services/sentry";
import { TabSync } from "services/tabSync";
import { Workbox } from "workbox-window";
import { WorkboxLifecycleEvent } from "workbox-window/utils/WorkboxEvent";
import create from "zustand";

type State = {
  isActivating: boolean;
  hasUpdate: boolean;
  setHasUpdate(): void;
  resetUpdateState(): void;
  setActivated(): void;
};
const appVersionState = create<State>((set) => ({
  hasUpdate: false,
  isActivating: false,
  setHasUpdate: () => set({ hasUpdate: true }),
  resetUpdateState: () => set({ hasUpdate: false }),
  setActivated: () => set({ isActivating: true }),
}));
export { appVersionState as useAppVersionState };

function register() {
  const waitingMsg = `A new service worker has installed, but it can't activate until all tabs running the current version have fully unloaded.`;
  const wb = new Workbox("/service-worker.js");
  let registration: ServiceWorkerRegistration | undefined;

  function sendSwEvent(
    event: WorkboxLifecycleEvent,
    skipReport: boolean = false
  ) {
    if (skipReport) return;
    const message = `sw_event (${event.type}) ${
      event.isUpdate ? "update" : "new"
    }`;
    SentryService.addBreadcrumb({
      category: "service-worker",
      message,
      level: SentryService.Severity.Info,
      data: {
        waiting: registration?.waiting,
        active: registration?.active,
        installing: registration?.installing,
        scope: registration?.scope,
      },
    });
  }

  function checkForUpdates() {
    const timeStr = new Date().toLocaleString("en-GB", {
      timeZoneName: "short",
    });
    const updateMsg = `Checking for app updates : ${timeStr}`;
    SentryService.addBreadcrumb({
      category: "service-worker",
      message: updateMsg,
      level: SentryService.Severity.Info,
    });
    wb.update();
  }

  let intervalId: any;
  function enableUpdates() {
    clearInterval(intervalId);
    intervalId = setInterval(checkForUpdates, 600 * 1000);
  }
  enableUpdates();
  (window as any).app = {
    update: checkForUpdates,
    pauseUpdates() {
      clearInterval(intervalId);
    },
    enableUpdates,
    get reg() {
      return registration;
    },
  };

  let isReloadingPage = false;

  async function onActivation() {
    if (!isReloadingPage) {
      isReloadingPage = true;
      try {
        clearInterval(intervalId);
        appVersionState.getState().setActivated();
        await SentryService.close(800);
      } catch (error) {}
      // setTimeout(() => window.location.reload(), 200);
    }
  }

  wb.addEventListener("activated", (event) => {
    sendSwEvent(event);
    // `event.isUpdate` will be true if another version of the service
    // worker was controlling the page when this version was registered.
    // if isUpdate
    // a service worker was already controlling when register was called on page load
    // else
    // If your service worker is configured to precache assets, those
    // assets should all be available now.
  });
  // sw change
  wb.addEventListener("controlling", (event) => {
    sendSwEvent(event);
    onActivation();
  });
  wb.addEventListener("externalactivated", (event) => {
    sendSwEvent(event);
    onActivation();
  });
  navigator.serviceWorker.addEventListener("controllerchange", () => {
    sendSwEvent({ type: "navigator_controllerchange" } as any);
    onActivation();
  });
  function onWaitingSW(event: WorkboxLifecycleEvent) {
    console.info(waitingMsg);
    sendSwEvent(event);
    appVersionState.getState().setHasUpdate();
  }
  wb.addEventListener("waiting", onWaitingSW);
  wb.addEventListener("externalwaiting", onWaitingSW);
  //
  wb.addEventListener("redundant", (event) => {
    sendSwEvent(event);
    appVersionState.getState().resetUpdateState();
  });

  async function skipWaiting(automatic: boolean = false) {
    try {
      // workbox `messeageSW` doesn't work most of the time
      const reg = await navigator.serviceWorker.getRegistration();
      if (!reg?.waiting) {
        console[automatic ? "info" : "error"]("No waiting updates.");
        return;
      }
      clearInterval(intervalId);
      reg?.waiting?.postMessage({ type: "SKIP_WAITING" });
    } catch (error) {
      SentryService.reportWithScope(error);
    }
  }
  let registrationComplete = false;
  (async function initRegistration() {
    registration = await wb.register();
    SentryService.addBreadcrumb({
      category: "service-worker",
      message: registration
        ? `Service Worker registered with scope: ${registration.scope}`
        : "Service Worker registration empty",
      level: registration
        ? SentryService.Severity.Info
        : SentryService.Severity.Error,
    });
    //
    const swInfo = await sendMessageSw(
      { type: "GET_VERSION" },
      registration?.active
    );
    if (typeof swInfo === "object" && swInfo !== null) {
      const activeSwInfo = swInfo as SW_RELEASE_INFO;
      SentryService.addBreadcrumb({
        category: "service-worker",
        message: `Service Worker version: ${activeSwInfo.RELEASE}`,
        level: SentryService.Severity.Info,
      });
      // user likely cleared cache or force reloaded app
      // using an older version of sw than the page
      if (activeSwInfo.RELEASE !== APP_VERSION.RELEASE) {
        return skipWaiting();
      } else {
        registrationComplete = true;
      }
    }
  })().catch((err) => {
    SentryService.reportWithScope(err);
    console.log(err);
  });
  return {
    skipWaiting,
    checkForUpdates,
    get registered() {
      return registrationComplete;
    },
  };
}

export let wbRegistration: ReturnType<typeof register> | null = null;

function registerSW() {
  if ("serviceWorker" in navigator) {
    wbRegistration = register();
  }
}

async function unregisterSW() {
  if ("serviceWorker" in navigator) {
    await navigator.serviceWorker.ready
      .then((registration) => {
        return registration.unregister();
      })
      .catch((error) => {
        SentryService.reportWithScope(error);
      });
    // if a service worker becomes active in another tab later, should reload
    navigator.serviceWorker.addEventListener("controllerchange", () => {
      // BROWSER.reload();
      console.log("unregistered tab: controller changed");
    });
  }
}

export const SW = {
  registerSW,
  unregisterSW,
  cleanUpServiceWorkerAndReload,
};

/**
 * clear service worker & caches
 * alternative: post msg & cleanup from sw
 */
async function cleanUpServiceWorkerAndReload() {
  try {
    const registrations = await navigator.serviceWorker.getRegistrations();
    const unregisterPromises = registrations.map((registration) =>
      registration.unregister()
    );
    const allCaches = await caches.keys();
    const cacheDeletionPromises = allCaches.map((cache) =>
      caches.delete(cache)
    );
    await Promise.all([...unregisterPromises, ...cacheDeletionPromises]);
    await BROWSER.clearAllBrowserCachesNReload();
  } catch (err) {
    TabSync.notifyOtherTabs("RELOAD_MSG");
    BROWSER.reload();
  }
}

/**
 * Targets controlling sw
 * workbox `messageSw` gives priority to waiting sw.
 * @param message
 * @param targetSW
 */
function sendMessageSw(message: any, targetSW?: ServiceWorker | null) {
  // This wraps the message posting/response in a promise, which will resolve if the response doesn't
  // contain an error, and reject with the error if it does. If you'd prefer, it's possible to call
  // controller.postMessage() and set up the onmessage handler independently of a promise, but this is
  // a convenient wrapper.
  if (!targetSW) return null;
  return new Promise(function (resolve, reject) {
    const messageChannel = new MessageChannel();
    messageChannel.port1.onmessage = function (event) {
      if (event.data.error) {
        reject(event.data.error);
      } else {
        resolve(event.data);
      }
    };

    // This sends the message data as well as transferring messageChannel.port2 to the service worker.
    // The service worker can then use the transferred port to reply via postMessage(), which
    // will in turn trigger the onmessage handler on messageChannel.port1.
    // See https://html.spec.whatwg.org/multipage/workers.html#dom-worker-postmessage
    targetSW?.postMessage(message, [messageChannel.port2]);
  });
}
